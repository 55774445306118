import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
import AttributeListBox from "../../Components/AttributeListBox";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import { Divider } from "@mui/material";
import { attendeeStatuses, classesExamples } from "../CommonVariables";
import InputBoxElement from "../../Components/InputBox/InputBox";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { RevertList } from "../../Components/RevertList";
import AdvancedClassSettings from "./AdvancedClassSettings";
import "../studioSettings.scss";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import MultipleSelectCheckmarks from "../../../components/MultipleSelectCheckbox/MultipleSelectCheckbox";
import BasicModal from "../../../components/Modal/Modal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import { Skeleton } from "@mui/material";
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ClassBucketBox(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      key: id,
      value: value,
    });
  };

  const handleRateUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate[id],
      value
    );
    setChanges(newRev);

    // UPDATE CLASS SETTINGS GROUP/INTRO/PRIVATE RATE
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_RATE",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      rateId: id,
      newRate: value,
    });
  };

  function ZeroComponent() {
    let booleanConverter = {
      true: "Paid A Different Amount",
      false: "Not Paid",
    };

    return (
      <>
        <Box className="settings-page__box settings-page__box--inline">
          <Typography>
            {settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionName +
              " with zero " +
              settings[getUrlVariableValue("settingsId")].generalSettings
                .clientsName +
              ", are :"}
          </Typography>
          <SelectorBox
            key={props.classType + "-zero"}
            onChange={handleRateUpdate}
            id="includeZero"
            default={
              typeof settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets[props.classType].rate.includeZero === "boolean"
                ? booleanConverter[
                    settings[getUrlVariableValue("settingsId")].classSettings
                      .classBuckets[props.classType].rate.includeZero
                  ]
                : settings[getUrlVariableValue("settingsId")].classSettings
                    .classBuckets[props.classType].rate.includeZero
            }
            size={400}
            options={[
              "Not Paid",
              "Paid The Base Rate",
              "Paid A Different Amount",
            ]}
            label="Zero Attendees"
            name="Zero Attendees"
          ></SelectorBox>
        </Box>
        <Divider className="settings-page__divider settings-page__divider--light" />
      </>
    );
  }

  return (
    <Box>
      <Box className="settings-page__box settings-page__box--inline">
        <Typography>
          For{" "}
          {props.classType}{" "}
          {settings[getUrlVariableValue("settingsId")].generalSettings
            .sessionsName +
            ", " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .specialistsName +
            " are paid a"}
        </Typography>
        <SelectorBox
          key={props.classType + "-structure"}
          onChange={handleRateUpdate}
          id="structure"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].rate.structure
          }
          size={400}
          options={[
            "Base Rate + Per Head Bonus",
            "Flat Rate",
            "Custom Amount",
            "None (Zero)",
          ]}
          label="Rate"
          name="Rate"
        ></SelectorBox>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />

      {settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate.structure !== "None (Zero)" ? (
        <ZeroComponent />
      ) : null}

      {settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate.structure === "Base Rate + Per Head Bonus" ? (
        <AfterAttendeeBonus classType={props.classType} />
      ) : null}

      {settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate.structure !== "None (Zero)" ? (
        <StatusComponent classType={props.classType} />
      ) : null}

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Max " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionName +
            " capacity:"}
        </Typography>
        <InputBoxElement
          key={props.classType + "-capacity"}
          onChange={handleBucketUpdate}
          id="capacity"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].capacity
          }
          types={["number"]}
          size={100}
          label="Attendees"
        ></InputBoxElement>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {props.classType}{" "}
          {
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionsName
          }{" "}
          are any{" "}
          {
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionName
          }{" "}
          with name(s):
        </Typography>
        <AttributeListBox
          key={props.classType + "-names"}
          multiple={true}
          freeSolo
          limitTags={20}
          onChange={handleBucketUpdate}
          id="names"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].names
          }
          size={400}
          label="Class Names"
          options={classesExamples}
        ></AttributeListBox>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />

      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Advanced {props.classType} Settings
        </Typography>
        <Box className="settings-page__box settings-page__box--inline">
          <BasicModal openIcon={<EditRoundedIcon />}>
            <AdvancedClassTypeSettings
              classType={props.classType}
              key={props.classType + "advancedClassSettings"}
            ></AdvancedClassTypeSettings>
          </BasicModal>
        </Box>
      </Box>
    </Box>
  );
}

function StatusComponent(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {
   
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      key: id,
      value: value,
    });
  };

  return (
    <>
      <Box className="settings-page__box settings-page__box--inline">
        <Typography>
          {settings[getUrlVariableValue("settingsId")].generalSettings
            .specialistsName +
            " are paid for any " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .clientsName +
            " with the status(es) of"}
        </Typography>
        <MultipleSelectCheckmarks
          tag="Status"
          key={props.classType + "-status"}
          onChange={handleBucketUpdate}
          id="statuses"
          multiple
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].statuses
          }
          size={400}
          //options={attendeeStatuses}
          options={
            settings[getUrlVariableValue("settingsId")].generalSettings
              .attendeeStatuses
          }
          label="Statuses"
          name="Statuses"
        ></MultipleSelectCheckmarks>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
    </>
  );
}

function AfterAttendeeBonus(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleRateUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ].rate[id],
      value
    );
    setChanges(newRev);

    // UPDATE CLASS SETTINGS GROUP/INTRO/PRIVATE RATE
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_RATE",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      rateId: id,
      newRate: value,
    });
  };

  return (
    <>
      <Box className="settings-page__box settings-page__box--inline">
        <Typography>
          {"Per Head Bonus starts AFTER " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .clientName +
            " number:"}
        </Typography>
        <SelectorBox
          key={props.classType + "-status"}
          onChange={handleRateUpdate}
          id="defaultAfterAttendee"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].rate.defaultAfterAttendee
          }
          size={400}
          options={createCapacityArray(
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].capacity
          )}
          label="Statuses"
          name="Statuses"
        ></SelectorBox>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
    </>
  );
}

function createCapacityArray(number) {
  let num = parseInt(number.split("~")[1]);
  let numArray = [];
  for (let i = 0; i <= num; i++) {
    numArray.push("" + i);
  }
  return numArray;
}

function AdvancedClassTypeSettings(props) {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleBucketUpdate = (value, id) => {
    
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      props.classType + "-" + id,
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[
        props.classType
      ][id],
      value
    );
    setChanges(newRev);
    dispatch({
      type: "UPDATE_CLASS_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      classType: props.classType,
      key: id,
      value: value,
    });
  };

  return (
    <Box>
      {/* <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {props.classType}{" "}
          {
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionsName
          }{" "}
          are any{" "}
          {
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionName
          }{" "}
          with name(s):
        </Typography>
        <AttributeListBox
          key={props.classType + "-names"}
          multiple={true}
          freeSolo
          onChange={handleBucketUpdate}
          id="names"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].names
          }
          size={400}
          label="Class Names"
          options={classesExamples}
        ></AttributeListBox>
      </Box>
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Max " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionName +
            " capacity:"}
        </Typography>
        <InputBoxElement
          key={props.classType + "-capacity"}
          onChange={handleBucketUpdate}
          id="capacity"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].capacity
          }
          types={["number"]}
          size={100}
          label="Attendees"
        ></InputBoxElement>
      </Box> */}
      {/* <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          If no pay amount can be calculated, the default amount should:
        </Typography>
        <InputBoxElement
          key={props.classType + "-default"}
          onChange={handleBucketUpdate}
          id="defaultPay"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].defaultPay
          }
          types={["dollar", "percent"]}
          size={100}
          label="Amount"
        ></InputBoxElement>
      </Box> */}
      {/* this.hasSignUpsPostSession = false;
        this.isAffectedByAlternateClientPayment = false;
        this.isStandardSessionType = false;
        this.includeSessionTimeInName = true;
        this.wouldHaveBeenFullApplicable = true; */}
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Track membership signups after " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionsName}
        </Typography>
        <BooleanToggleElement
          key={props.classType + "-hasSignUpsPostSession"}
          onChange={handleBucketUpdate}
          id="hasSignUpsPostSession"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].hasSignUpsPostSession
              ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].hasSignUpsPostSession
              : props.classType === "Intro"
          }
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Is affected by alternate{" "}
          {
            settings[getUrlVariableValue("settingsId")].generalSettings
              .memberName
          }{" "}
          payment:
        </Typography>
        <BooleanToggleElement
          key={props.classType + "-isAffectedByAlternateClientPayment"}
          onChange={handleBucketUpdate}
          id="isAffectedByAlternateClientPayment"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].isAffectedByAlternateClientPayment
              ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType]
                  .isAffectedByAlternateClientPayment
              : props.classType === "Group"
          }
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Is standard " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionName +
            " type:"}
        </Typography>
        <BooleanToggleElement
          key={props.classType + "-isStandardSessionType"}
          onChange={handleBucketUpdate}
          id="isStandardSessionType"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].isStandardSessionType
              ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].isStandardSessionType
              : props.classType === "Group"
          }
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          {"Include " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .sessionName +
            " length in name:"}
        </Typography>
        <BooleanToggleElement
          key={props.classType + "-includeSessionTimeInName"}
          onChange={handleBucketUpdate}
          id="includeSessionTimeInName"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].includeSessionTimeInName
              ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].includeSessionTimeInName
              : true
          }
        ></BooleanToggleElement>
      </Box>

      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          "Would have been full" applicable":
        </Typography>
        <BooleanToggleElement
          key={props.classType + "-wouldHaveBeenFullApplicable"}
          onChange={handleBucketUpdate}
          id="wouldHaveBeenFullApplicable"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].wouldHaveBeenFullApplicable
              ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].wouldHaveBeenFullApplicable
              : props.classType === "Group"
          }
        ></BooleanToggleElement>
      </Box>
      {/* paidOnSignUps */}
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Pay attendee bonus on post class signups:
        </Typography>
        <BooleanToggleElement
          key={props.classType + "-paidOnSignUps"}
          onChange={handleBucketUpdate}
          id="paidOnSignUps"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].paidOnSignUps
              ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].paidOnSignUps
              : props.classType === "Intro" &&
                settings[getUrlVariableValue("settingsId")].classSettings
                  .general.introSignUps
          }
        ></BooleanToggleElement>
      </Box>
      {/* excludeOverlapInPayments */}
      <Divider className="settings-page__divider settings-page__divider--light" />
      <Box className="settings-page__box settings-page__box--inline">
        <Typography className="settings-page__text">
          Exclude Payment for Overlapping Sessions:
        </Typography>
        <BooleanToggleElement
          key={props.classType + "-excludeOverlapInPayments"}
          onChange={handleBucketUpdate}
          id="excludeOverlapInPayments"
          default={
            settings[getUrlVariableValue("settingsId")].classSettings
              .classBuckets[props.classType].excludeOverlapInPayments
              ? settings[getUrlVariableValue("settingsId")].classSettings
                  .classBuckets[props.classType].excludeOverlapInPayments
              : false
          }
        ></BooleanToggleElement>
      </Box>
    </Box>
  );
}

const tabElement = (value) => {
  if (value === "Advanced") {
    return <AdvancedClassSettings></AdvancedClassSettings>;
  } else {
    return (
      <ClassBucketBox
        key={value + "-element-box"}
        classType={value}
      ></ClassBucketBox>
    );
  }
};
export default function ClassSettings() {
  const { getUID } = useAuth();
  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const [tab, setTab] = useState(
    settings.hasOwnProperty("1") && settings.hasOwnProperty("staff")
      ? Object.keys(
          settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
        ).sort()[0]
      : "Group"
  );
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event) => {
    let id = event.target.id;
    let buckets = Object.keys(
      settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
    ).sort();
    //let buckets = Object.keys(settings[query.get("settingsId")].classSettings.classBuckets);

    setTab(id);
    if (id == "Advanced") {
      setTabIndex(buckets.length);
    }

    for (let i = 0; i < buckets.length; i++) {
      if (buckets[i] === id) {
        setTabIndex(i);
        return;
      }
    }
  };

  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {settings[getUrlVariableValue("settingsId")].generalSettings
          .sessionName + " Structures"}
      </Typography>

      <Tabs
        className="settings-page__tabs"
        value={tabIndex}
        onChange={handleTabChange}
        centered
      >
        {Object.keys(
          settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
        )
          .sort()
          .map((key, index) => (
            <Tab
              label={key.replace(/(?<!^)([A-Z])/g, "-$1")}
              {...tabProps(index)}
              className={`settings-page__tab`}
              key={key}
              id={key}
            />
          ))}

        <Tab
          className={`settings-page__tab `}
          {...tabProps(
            Object.keys(
              settings[getUrlVariableValue("settingsId")].classSettings
                .classBuckets
            ).length
          )}
          key={"Advanced"}
          label={"Advanced"}
          id={"Advanced"}
        />
      </Tabs>

      {tabElement(tab)}
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
