import { addDay } from "../../utility-functions/GeneralFunctions/AddDay.js";
import { CommissionObject } from "../../utility-functions/ReportCompiler/classes/CommissionObject.js";
import { CommissionBucket } from "../../utility-functions/QuestionObjects/CommissionBuckets.js";
import { findStaffObject } from "../../utility-functions/utility-functions.js";
import { API } from "aws-amplify";
import { getFirebaseJwtToken } from "../../utility-functions/tokenUtility.js";

//ignorePreviousAgreements

/**** CLASSES ****/
export class CommissionCreation {
  constructor(reportCompilerState) {
    this.reportCompilerState = reportCompilerState;
    this.isInitialized = true;
  }

  /**** CREATE AGREEMENTS FUNCTIONS ****/
  createAgreements() {
    let commissionAgreements = [];

    let agreements = this.reportCompilerState.inputFilesArrays.AGREE;
    for (let i = 0; i < agreements.length; i++) {
      let description = agreements[i].description;
      let classNameFound = this.includesClassName(description);
      if (classNameFound) {
        description = description.toUpperCase();
      }
      let commissionObject = new CommissionObject(
        agreements[i].location,
        agreements[i].date,
        agreements[i].price,
        agreements[i].clientFirstName,
        agreements[i].clientLastName,
        description
      );
      for (let j = 0; j < agreements[i].comments.length; j++) {
        commissionObject.comment.push(agreements[i].comments[j]);
      }
      let upgradeType = this.upgradeOrDowngrade(
        agreements[i].previousAgreements,
        agreements[i].description
      );
      commissionObject.addPreviousPurchase(
        agreements[i].previousAgreementNumber,
        agreements[i].previousAgreementsArray,
        agreements[i].previousAgreements,
        agreements[i].previousAgreementDate,
        upgradeType,
        null
      );
      this.findCommissionType(commissionObject);
      commissionObject.notes = agreements[i].notes;

      this.addAgreementCommissionSalesPeople(commissionObject, agreements[i]);
      if (this.agreementIsCommissionable(commissionObject)) {
        this.addAgreementSalesPay(commissionObject, agreements[i]);
      }
      commissionAgreements.push(commissionObject);
    }

    return commissionAgreements;
  }

  upgradeOrDowngrade(prevAgree, newAgree) {
    let type = "N/A";
    let previous = -1;
    let newest = -1;

    let renewal = false;

    if (prevAgree.includes("[CANCELLED")) {
      let dateStr = prevAgree.substring(
        prevAgree.indexOf(" ") + 1,
        prevAgree.indexOf("]")
      );
      let dateArray = dateStr.split("/");
      let correctDateStr =
        dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1];
      let cancelDate = new Date(correctDateStr);
      let belPayPeriods = this.reportCompilerState.payrollInformation
        .belPayPeriods;

      try {
        if (
          belPayPeriods[0].getTime() > cancelDate.getTime() ||
          addDay(belPayPeriods[1]).getTime() <= cancelDate.getTime()
        ) {
          renewal = true;
        }
      } catch (e) {
        console.log(e);
      }

    }

    if (prevAgree.includes(newAgree)) {
      previous = 1;
      newest = 1;
      type = "Renewal";
    }

    if (prevAgree.includes("Passport")) {
      previous = 20;
    } else if (
      prevAgree.includes("Membership 3") ||
      prevAgree.includes("Unlimited")
    ) {
      previous = 10;
    } else if (
      prevAgree.includes("Membership 2") ||
      prevAgree.includes("8 Classes")
    ) {
      previous = 8;
    } else if (
      prevAgree.includes("Membership 1") ||
      prevAgree.includes("4 Classes")
    ) {
      previous = 4;
    } else if (
      prevAgree.includes("1 x Single") ||
      prevAgree.includes("Single Class")
    ) {
      previous = 1;
    }

    if (newAgree.includes("Passport")) {
      newest = 20;
    } else if (
      newAgree.includes("Membership 3") ||
      newAgree.includes("Unlimited")
    ) {
      newest = 10;
    } else if (
      newAgree.includes("Membership 2") ||
      newAgree.includes("8 Classes")
    ) {
      newest = 8;
    } else if (
      newAgree.includes("Membership 1") ||
      newAgree.includes("4 Classes")
    ) {
      newest = 4;
    } else if (
      newAgree.includes("1 x Single") ||
      newAgree.includes("Single Class")
    ) {
      newest = 1;
    }

    if (previous === -1 || newest === -1) {
      type = "N/A";
    } else if (previous > newest) {
      type = "Downgrade";
    } else if (previous < newest) {
      type = "Upgrade";
    }
    if (renewal) {
      return type + " (after cancel)";
    }
    return type;
  }

  findCommissionType(commissionObject) {
    if (this.isUpgradeAgreement(commissionObject)) {
      commissionObject.type = "Upgrade/Downgrade";
    } else if (this.isIntroAgreement(commissionObject)) {
      commissionObject.type = "Post-Intro Agreement";
    } else {
      commissionObject.type = "Non-Intro Agreement";
    }
  }

  isUpgradeAgreement(commissionObject) {
    return commissionObject.previousPurchases.previousAmount > 0;
  }

  isIntroAgreement(commissionObject) {
    const classes = this.reportCompilerState.studiosInformation.classes;
    for (let i = 0; i < classes.length; i++) {
      const classObj = classes[i];
      if (classObj.type.includes("Intro")) {
        for (let j = 0; j < classObj.attendeeList.length; j++) {
          const attendee = classObj.attendeeList[j];
          if (
            attendee.name === commissionObject.customer &&
            attendee.signedUpAfterSession
          ) {
            this.addIntroSpecificSalesPeople(
              commissionObject,
              attendee,
              classObj
            );
            return true;
          }
        }
      }
    }
    return false;
  }

  addIntroSpecificSalesPeople(commissionObject, attendee, classObj) {
    commissionObject.addSalesPerson("open", attendee.loggedBy);
    commissionObject.addSalesPerson("instructor", classObj.instructor);
  }

  addAgreementCommissionSalesPeople(commissionObject, agreement) {
    commissionObject.addSalesPerson(
      "close",
      agreement.salespeople.PrimarySalesperson
    );
    commissionObject.addSalesPerson(
      "secondary",
      agreement.salespeople.SecondarySalesperson
    );
  }

  agreementIsCommissionable(commissionObject) {
    //Order 1: sold by staff
    if (
      this.isStaff(commissionObject.customer) &&
      this.reportCompilerState.inputFilesArrays.questions
        .noCommissionForStaffBoughtProducts.value
    ) {
      commissionObject.comment.push("Staff Bought Agreement");
      return false;
    }

    let exceptionInfo = this.checkCommissionExceptions(commissionObject);
    let exception = exceptionInfo.exception;
    if (exception === null || exception.rateFrequency === "Pay As Normal") {
      //carry on
    } else if (exception.rateFrequency === "Never Pay") {
      return false;
    } else if (exception.rateFrequency === "Always Pay") {
      return true;
    }

    //Order 2: specifically not paying single group classes or a private training sessions
    // if(commissionObject.description.includes("Single Class") && !reportCompilerState.inputFilesArrays.questions.singleClassCommissionPay.value){
    //   return false;
    // }else if( (commissionObject.description.includes("Private Training Session") || commissionObject.description.includes("Private Sessions")) && !reportCompilerState.inputFilesArrays.questions.privateTrainingCommission.value){
    //     return false;
    // }

    let previousPurchaseSingleAgreement = false;
    let singleAgreements = this.reportCompilerState.inputFilesArrays.questions.singleAgreements;
    for (let a = 0; a < singleAgreements.length; a++) {
      if (commissionObject.previousPurchases.previousPurchase.includes(singleAgreements[a])) {
        previousPurchaseSingleAgreement = true;
      }
    }

    //Order 3:
    if (
      (commissionObject.description.includes("Annual Membership") ||
        commissionObject.description.includes("Unlimited Classes a Year") ||
        commissionObject.description.includes("for the Year")) &&
      (commissionObject.previousPurchases.previousAmount > 1 || this.reportCompilerState.inputFilesArrays.questions.ignorePreviousAgreements.value)
    ) {
      return false;
    } else if (
      commissionObject.type === "Upgrade/Downgrade" &&
      ((commissionObject.previousPurchases.previousAmount) === 1 || this.reportCompilerState.inputFilesArrays.questions.ignorePreviousAgreements.value) &&
      //commissionObject.previousPurchases.previousPurchase.includes("Single Class") 
      previousPurchaseSingleAgreement
      &&
      this.reportCompilerState.inputFilesArrays.questions
        .singleClassPrevNewAgreement.value
    ) {
      commissionObject.type = "Non-Intro Agreement";
      commissionObject.comment.push(
        "Customer only bought one previous single class"
      );
      return true;
    } else if (
      commissionObject.type === "Upgrade/Downgrade" &&
      (commissionObject.previousPurchases.previousAmount > 1 || this.reportCompilerState.inputFilesArrays.questions.ignorePreviousAgreements.value) &&
      //commissionObject.previousPurchases.previousPurchase.includes("Single Class") 
      previousPurchaseSingleAgreement
      &&
      this.reportCompilerState.inputFilesArrays.questions
        .singleClassPrevNewAgreement.value
    ) {
      commissionObject.comment.push(
        "Multiple Previous Agreements - Unable to determine if commissionable"
      );
    }

    if (
      (commissionObject.previousPurchases.type === "Upgrade/Downgrade" ||
        commissionObject.previousPurchases.type === "N/A") &&
      (commissionObject.previousPurchases.previousAmount > 1 || this.reportCompilerState.inputFilesArrays.questions.ignorePreviousAgreements.value)
    ) {
      commissionObject.comment.push("Unable to determine if commissionable");
    }

    let bucket = this.findCommissionBucket(commissionObject.type);

    if (bucket === null) {
      return false;
    } else if (bucket.amount === 0) {
      return false;
    } else if (bucket.amount !== 0) {
      if (bucket.type === "Upgrade/Downgrade") {
        if (
          !(
            this.reportCompilerState.inputFilesArrays.questions
              .upgradePaymentTypes.length === 0 ||
            this.reportCompilerState.inputFilesArrays.questions.upgradePaymentTypes.includes(
              commissionObject.previousPurchases.type
            )
          )
        ) {
          return false;
        }
      }
      return true;
    }

    return true;
  }

  addAgreementSalesPay(commissionObject) {
    let commissionArray = this.calculateAgreementPayAmounts(commissionObject);

    commissionObject.salespay.open = commissionArray[0];
    commissionObject.salespay.close = commissionArray[1];
    commissionObject.salespay.instructor = commissionArray[2];
    commissionObject.salespay.secondary = commissionArray[3];

    this.addInstructorBonus(commissionObject);
  }

  calculateAgreementPayAmounts(commissionObject) {
    let commissionArray = [0, 0, 0, 0];
    let split = 0;

    let commissionLogic = this.findCommissionBucket(commissionObject.type);
    let exceptionInfo = this.checkCommissionExceptions(commissionObject);
    let exceptionFound = exceptionInfo.exception;
    let exceptionComment = exceptionInfo.comment;
    if (
      exceptionFound !== null &&
      exceptionFound.rateFrequency !== "Pay As Normal"
    ) {
      if (exceptionFound.rateNormal !== "Regular Rate") {
        commissionLogic = new CommissionBucket("Exception");
        commissionLogic.setAmount(exceptionFound.rate);
        commissionLogic.setSplit(exceptionFound.split);
        commissionLogic.setAllSalesPersonPay(exceptionFound.salespeople);
      }
      commissionObject.comment.push(exceptionComment);
    }

    if (commissionLogic !== null) {
      if (commissionLogic.salesPeoplePay["Open"]) {
        let rate = this.findStaffAgreementCommissionRate(
          commissionObject.salespeople.open,
          commissionLogic,
          commissionObject.location
        );
        commissionArray[0] = rate;
        if (rate !== 0) {
          split += 1;
        }
      }
      if (commissionLogic.salesPeoplePay["Close"]) {
        let rate = this.findStaffAgreementCommissionRate(
          commissionObject.salespeople.close,
          commissionLogic,
          commissionObject.location
        );
        commissionArray[1] = rate;
        if (rate !== 0) {
          split += 1;
        }
      }
      if (commissionLogic.salesPeoplePay["Instructor"]) {
        let rate = this.findStaffAgreementCommissionRate(
          commissionObject.salespeople.instructor,
          commissionLogic,
          commissionObject.location
        );
        commissionArray[2] = rate;
        if (rate !== 0) {
          split += 1;
        }
      }
      if (commissionLogic.salesPeoplePay["Second"]) {
        let rate = this.findStaffAgreementCommissionRate(
          commissionObject.salespeople.secondary,
          commissionLogic,
          commissionObject.location
        );
        commissionArray[3] = rate;
        if (rate !== 0) {
          split += 1;
        }
      }

      if (commissionLogic.split === false) {
        split = 1;
      }
    }

    if (split > 0) {
      // let payment = //this.findCommissionPayment(commissionObject)
      let payment = commissionObject.payment;
      let commissionSplit = payment / split;
      for (let j = 0; j < commissionArray.length; j++) {
        if (commissionArray[j] < 1) {
          commissionArray[j] = commissionArray[j] * commissionSplit;
        } else {
          commissionArray[j] = commissionArray[j] / split;
        }
      }
    }

    return commissionArray;
  }

  findCommissionBucket(type) {
    let commissionObjects = this.reportCompilerState.inputFilesArrays.questions
      .commissionPayObjects;
    for (let i = 0; i < commissionObjects.length; i++) {
      if (commissionObjects[i].type === type) {
        return commissionObjects[i];
      }
    }
    return null;
  }

  findCommissionPayment(commissionObject) {
    if (
      commissionObject.description.includes("Annual Membership") ||
      commissionObject.description.includes("Unlimited Classes a Year") ||
      commissionObject.description.includes("for the Year")
    ) {
      if (
        this.reportCompilerState.inputFilesArrays.questions.annualCommission
          .value > 1
      ) {
        return this.reportCompilerState.inputFilesArrays.questions
          .annualCommission.value;
      } else if (
        this.reportCompilerState.inputFilesArrays.questions.annualCommission
          .value <= 1
      ) {
        return (
          this.reportCompilerState.inputFilesArrays.questions.annualCommission
            .value * commissionObject.payment
        );
      }
    }
    return commissionObject.payment;
  }

  addInstructorBonus(commissionObject) {
    commissionObject.salespay.instructor += this.reportCompilerState.inputFilesArrays.questions.sessionSignupBonus.value;
  }

  isStaff(name) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    let isStaff = false;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        isStaff = true;
      }
    }
    return isStaff;
  }

  /**** CREATE COMMISSION FUNCTIONS ****/

  /* Type, Category, Location, Date Sold, Sold To, Payment, What was sold, Previous Agreement (if applicable), Upgrade or Downgrade (if applicable), openedsalesperson (if applicable), opened salesperson pay (if applicable), closed salesperson (if applicable), opened salesperson pay (if applicable), */

  createCommission() {
    if (this.reportCompilerState.inputFilesArrays.questions.salesBuckets.length !== 0) {
      let commissionArray = this.createSalesCommission();
      this.organizeCommissionArray(commissionArray);
      this.reportCompilerState.studiosInformation[
        "commissions"
      ] = commissionArray;

      return commissionArray;
    }
    let agreementArray = this.createAgreements();
    let retailArray = this.createRetail();
    let commissionArray = this.combineCommissionArrays(
      agreementArray,
      retailArray
    );

    let agreementDiscountUncommissioned = this.reportCompilerState
      .inputFilesArrays.questions.agreementDiscountPercentageNoCommission.value;
    if (
      agreementDiscountUncommissioned !== -1 &&
      agreementDiscountUncommissioned < 1
    ) {
      this.addCommissionDiscounts(commissionArray);
      this.commissionDiscountTooHighCheck(
        commissionArray,
        agreementDiscountUncommissioned
      );
    }
    this.organizeCommissionArray(commissionArray);

    this.reportCompilerState.studiosInformation[
      "commissions"
    ] = commissionArray;

    return commissionArray;
  }

  combineCommissionArrays(agreementArray, retailArray) {
    return agreementArray.concat(retailArray);
  }

  organizeCommissionArray(commissionObject) {
    commissionObject.sort((a, b) =>
      a.dateSold.getTime() > b.dateSold.getTime() ? 1 : -1
    );
  }

  addCommissionDiscounts(commissionArray) {
    let sales = this.reportCompilerState.inputFilesArrays.SALE;
    for (let i = 0; i < commissionArray.length; i++) {
      for (let j = 0; j < sales.length; j++) {
        if (
          commissionArray[i].dateSold.getTime() === sales[j].date.getTime() &&
          commissionArray[i].description === sales[j].description &&
          commissionArray[i].customer === sales[j].getCustomer()
        ) {
          commissionArray[i].discount = sales[j].discountPercent;
        }
      }
    }
  }

  commissionDiscountTooHighCheck(commissionArray, maxDiscount) {
    for (let i = 0; i < commissionArray.length; i++) {
      if (commissionArray[i].discount >= maxDiscount) {
        commissionArray[i].salespay.open = 0;
        commissionArray[i].salespay.close = 0;
        commissionArray[i].salespay.instructor = 0;
        commissionArray[i].salespay.secondary = 0;
        let discountPercent = "" + commissionArray[i].discount * 100 + "%";
        commissionArray[i].comment.push(
          "Discount of " + discountPercent + " too high"
        );
      }
    }
  }

  includesClassName(name) {
    let classNames = this.getClassNameArray();
    for (let i = 0; i < classNames.length; i++) {
      let commName = name.toLowerCase();
      let className = classNames[i].toLowerCase();
      if (commName.includes(className)) {
        //if(name.includes(classNames[i])){
        return true;
      }
    }
    return false;
  }

  getClassNameArray() {
    let classNames = [];
    let classBuckets = this.reportCompilerState.inputFilesArrays.questions
      .classBuckets;
    for (let i = 0; i < classBuckets.length; i++) {
      classNames = classNames.concat(classBuckets[i].classNames);
    }
    return classNames;
  }

  /****  CREATE RETAIL FUNCTIONS ****/
  createRetail() {
    let commissionRetail = [];
    let retail = this.reportCompilerState.inputFilesArrays.SALE;
    for (let i = 0; i < retail.length; i++) {
      if (
        retail[i].constructor.name !== "AgreementEvent" &&
        retail[i].commissionType !== "Agreement"
      ) {
        let description = retail[i].name;
        let classNameFound = this.includesClassName(description);
        if (classNameFound) {
          description = description.toUpperCase();
        }
        let commissionObject = new CommissionObject(
          retail[i].location,
          retail[i].date,
          retail[i].price,
          retail[i].clientFirstName,
          retail[i].clientLastName,
          description
        );
        commissionObject.addPreviousPurchase(
          0,
          [],
          "",
          "",
          "New",
          null
        );
        commissionObject.type = "Retail";
        commissionObject.notes = retail[i].notes;
        this.addRetailCommissionSalesPeople(commissionObject, retail[i]);
        if (this.retailIsCommissionable(commissionObject)) {
          this.addRetailSalesPay(commissionObject, retail[i]);
        }
        if (retail[i].comment !== undefined && retail[i].comment.length > 0) {
          commissionObject.comment.push(retail[i].comment);
        }
        commissionRetail.push(commissionObject);
      }
    }
    return commissionRetail;
  }

  addRetailCommissionSalesPeople(commissionObject, retailObj) {
    commissionObject.addSalesPerson(
      "close",
      retailObj.salespeople.PrimarySalesperson
    );
    commissionObject.addSalesPerson(
      "secondary",
      retailObj.salespeople.SecondarySalesperson
    );
    // commissionObject.addSalesPerson("close", retailObj.salesPerson);
    // commissionObject.addSalesPerson("secondary", "N/A");
  }

  retailIsCommissionable(commissionObject) {
    if (
      this.isStaff(commissionObject.customer) &&
      this.reportCompilerState.inputFilesArrays.questions
        .noCommissionForStaffBoughtProducts.value
    ) {
      commissionObject.comment.push("Staff Bought Agreement");
      return false;
    }

    let exceptionInfo = this.checkCommissionExceptions(commissionObject);
    let exception = exceptionInfo.exception;
    if (exception === null || exception.rateFrequency === "Pay As Normal") {
      //carry on
    } else if (exception.rateFrequency === "Never Pay") {
      return false;
    } else if (exception.rateFrequency === "Always Pay") {
      return true;
    }

    // if (
    //   (commissionObject.description.toUpperCase().includes("TOE") ||
    //     commissionObject.description.toUpperCase().includes("GRIP") ||
    //     commissionObject.description.includes("S0") ||
    //     commissionObject.description.includes("T0")) &&
    //   !this.reportCompilerState.inputFilesArrays.questions.toeSocksPay.value
    // ) {
    //   commissionObject.comment.push("No Payment for Toe Socks");
    //   return false;
    // }

    if (
      commissionObject.payment <
      this.reportCompilerState.inputFilesArrays.questions
        .noProductSalesCommissionBelow.value
    ) {
      commissionObject.comment.push(
        "No commmission on items < $" +
        this.reportCompilerState.inputFilesArrays.questions
          .noProductSalesCommissionBelow.value
      );
      return false;
    }

    if (
      commissionObject.notes.includes(
        this.reportCompilerState.inputFilesArrays.questions
          .productSaleCommentForNoCommission.value
      )
    ) {
      return false;
    }

    return true;
  }

  addRetailSalesPay(commissionObject) {
    let commissionArray = this.calculateRetailPayAmounts(commissionObject);

    commissionObject.salespay.open = commissionArray[0];
    commissionObject.salespay.close = commissionArray[1];
    commissionObject.salespay.instructor = commissionArray[2];
    commissionObject.salespay.secondary = commissionArray[3];
  }

  calculateRetailPayAmounts(commissionObject) {
    let commissionArray = [0, 0, 0, 0];
    let split = 0;

    let commissionLogic = this.findCommissionBucket(commissionObject.type);
    let exceptionInfo = this.checkCommissionExceptions(commissionObject);
    let exceptionFound = exceptionInfo.exception;
    let exceptionComment = exceptionInfo.comment;
    if (
      exceptionFound !== null &&
      exceptionFound.rateFrequency !== "Pay As Normal"
    ) {
      if (exceptionFound.rateNormal !== "Regular Rate") {
        commissionLogic = new CommissionBucket("Exception");
        commissionLogic.setAmount(exceptionFound.rate);
        commissionLogic.setSplit(exceptionFound.split);
        commissionLogic.setAllSalesPersonPay(exceptionFound.salespeople);
      }
      commissionObject.comment.push(exceptionComment);
    }

    if (commissionLogic !== null) {
      if (commissionLogic.salesPeoplePay["Open"]) {
        commissionArray[0] = this.findStaffRetailCommissionRate(
          commissionObject.salespeople.open,
          commissionLogic,
          commissionObject.location
        );
        split += 1;
      } else if (commissionLogic.salesPeoplePay["Close"]) {
        commissionArray[1] = this.findStaffRetailCommissionRate(
          commissionObject.salespeople.close,
          commissionLogic,
          commissionObject.location
        );
        split += 1;
      } else if (commissionLogic.salesPeoplePay["Instructor"]) {
        commissionArray[2] = this.findStaffRetailCommissionRate(
          commissionObject.salespeople.instructor,
          commissionLogic,
          commissionObject.location
        );
        split += 1;
      } else if (commissionLogic.salesPeoplePay["Second"]) {
        commissionArray[3] = this.findStaffRetailCommissionRate(
          commissionObject.salespeople.secondary,
          commissionLogic,
          commissionObject.location
        );
        split += 1;
      }

      if (commissionLogic.split === false) {
        split = 1;
      }
    }

    if (split > 0) {
      let payment = commissionObject.payment;
      let commissionSplit = payment / split;
      for (let j = 0; j < commissionArray.length; j++) {
        if (commissionArray[j] < 1) {
          commissionArray[j] = commissionArray[j] * commissionSplit;
        } else {
          commissionArray[j] = commissionArray[j];
        }
      }
    }

    return commissionArray;
  }

  findCommissionBucket(type) {
    let commissionObjects = this.reportCompilerState.inputFilesArrays.questions
      .commissionPayObjects;
    for (let i = 0; i < commissionObjects.length; i++) {
      if (commissionObjects[i].type === type) {
        return commissionObjects[i];
      }
    }
    return null;
  }

  salespersonIsValid(name) {
    return (
      name !== "System Admin" &&
      name !== null &&
      name !== "" &&
      this.isStaff(name)
    );
  }

  isStaff(name) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    let isStaff = false;
    for (let i = 0; i < staffArray.length; i++) {
      if (staffArray[i].isNamed(name)) {
        isStaff = true;
      }
    }
    return isStaff;
  }

  /**** Find Staff Commission Rate FUNCTIONS ****/
  findStaffAgreementCommissionRate(staffName, bucket, location) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    //const staffArray = this.reportCompilerState.inputFilesArrays.input1Pay;
    if (!this.isStaff(staffName)) {
      return 0;
    }
    for (let i = 0; i < staffArray.length; i++) {
      if (
        staffArray[i].isNamed(staffName) &&
        staffArray[i].getProperty(location, "agreementCommissionPercentage") !==
        null &&
        !isNaN(
          staffArray[i].getProperty(location, "agreementCommissionPercentage")
        )
      ) {
        return staffArray[i].getProperty(
          location,
          "agreementCommissionPercentage"
        );
      }
    }

    return bucket.amount;

  }

  findStaffRetailCommissionRate(staffName, bucket, location) {
    const staffArray = this.reportCompilerState.studiosInformation.staffArray;
    //const staffArray = this.reportCompilerState.inputFilesArrays.input1Pay;
    if (!this.isStaff(staffName)) {
      return 0;
    }
    for (let i = 0; i < staffArray.length; i++) {
      if (
        staffArray[i].isNamed(staffName) &&
        staffArray[i].getProperty(location, "retailCommissionPercentage") !==
        null &&
        !isNaN(
          staffArray[i].getProperty(location, "retailCommissionPercentage")
        )
      ) {
        return staffArray[i].getProperty(
          location,
          "retailCommissionPercentage"
        );
      }
    }

    return bucket.amount;
    // return findCommissionLogicAmount("Retail");
  }

  /**** HANDLE EXCEPTIONS FUNCTIONS *****/
  checkCommissionExceptions(commissionObject) {
    let exceptions = this.reportCompilerState.inputFilesArrays.questions
      .catchAllCommissionLogic;
    let foundException = null;
    let foundComment = "";
    for (let i = 0; i < exceptions.length; i++) {
      let comment = [];
      let exception = exceptions[i];
      if (exception.locations.length !== 0) {
        let locationMatch = false;
        for (let x = 0; x < exception.locations.length; x++) {
          if (commissionObject.location === exception.locations[x]) {
            locationMatch = true;
            comment.push("Location: " + commissionObject.location);
          }
        }
        if (!locationMatch) {
          continue;
        }
      }
      if (exception.types.length !== 0) {
        let typeMatch = false;
        for (let x = 0; x < exception.types.length; x++) {
          if (commissionObject.type === exception.types[x]) {
            typeMatch = true;
            comment.push("Type: " + commissionObject.type);
          }
        }
        if (!typeMatch) {
          continue;
        }
      }
      if (!commissionObject.description.includes(exception.itemName)) {
        continue;
      } else {
        if (exception.itemName.length > 0) {
          comment.push("Item: " + exception.itemName);
        }
      }
      if (exception.priceSequence !== "ANY PRICE" && exception.price !== -1) {
        if (
          exception.priceSequence === "Greater than" &&
          exception.price >= commissionObject.payment
        ) {
          continue;
        } else if (
          exception.priceSequence === "Less than" &&
          exception.price <= commissionObject.payment
        ) {
          continue;
        } else if (
          exception.priceSequence === "Equal to" &&
          exception.price !== commissionObject.payment
        ) {
          continue;
        } else if (
          exception.priceSequence === "Not equal to" &&
          exception.price === commissionObject.payment
        ) {
          continue;
        } else {
          comment.push(
            "Pricing: " + exception.priceSequence + " " + exception.price
          );
        }
      }
      if (
        exception.notes.length > 0 &&
        !commissionObject.notes.includes(exception.notes)
      ) {
        continue;
      } else {
        if (exception.notes.length > 0) {
          comment.push("Notes: " + exception.notes);
        }
      }

      foundComment = this.createComment(exception, comment);
      foundException = exception;
    }
    return { exception: foundException, comment: foundComment };
  }

  createComment(exception, comment) {
    let what = exception.rateFrequency;
    let why = "";
    if (comment.length === 0) {
      //None
    } else if (comment.length === 1) {
      why = " (" + comment[0] + ")";
    } else if (comment.length === 2) {
      why = " (" + comment[0] + " & " + comment[1] + ")";
    } else {
      why = " (" + comment[0] + " & " + comment[1] + "& ...)";
    }
    return what + why;
  }

















  ///////////////////////////////////////////////////////////

  createSalesCommission() {
    let commissionObjects = [];

    let agreements = this.reportCompilerState.inputFilesArrays.AGREE;
    for (let i = 0; i < agreements.length; i++) {
      let description = agreements[i].description;
      let classNameFound = this.includesClassName(description);
      if (classNameFound) {
        description = description.toUpperCase();
      }
      let commissionObject = new CommissionObject(
        agreements[i].location,
        agreements[i].date,
        agreements[i].price,
        agreements[i].clientFirstName,
        agreements[i].clientLastName,
        description
      );
      for (let j = 0; j < agreements[i].comments.length; j++) {
        commissionObject.comment.push(agreements[i].comments[j]);
      }

      let cancelDate = null
      if (agreements[i].previousAgreementsArray[0].includes("[CANCELLED") || agreements[i].previousAgreementsArray[0].includes("[SCHEDULED CANCEL")) {
        // Extract the content within the brackets
        let contentWithinBracketsMatch = agreements[i].previousAgreementsArray[0].match(/\[(.*?)\]/);
        if (contentWithinBracketsMatch && contentWithinBracketsMatch[1]) {
          // Find the date within the extracted content
          let dateMatch = contentWithinBracketsMatch[1].match(/(\d{1,2}\/\d{1,2}\/\d{4})/);
          if (dateMatch && dateMatch[1]) {
            // Convert the extracted date string to a Date object
            cancelDate = new Date(dateMatch[1]);
          }
        }
      }

      commissionObject.addPreviousPurchase(
        agreements[i].previousAgreementNumber,
        agreements[i].previousAgreementsArray,
        agreements[i].previousAgreements,
        agreements[i].previousAgreementDate,
        null,
        cancelDate
      );

      commissionObject.discount = agreements[i].discountPercent;
      commissionObject.notes = agreements[i].notes;

      let bucket = this.findSalesBucketType(commissionObject, "Agreement");

      let salesTypeObject = {
        bucketType: bucket.type,
        bucketName: bucket.name,
        statusType: "New",
        reactivation: false,
        sessionConversion: false,
        tier: 1,
        step: null,
        extendedType: ""
      };
      commissionObject.type = salesTypeObject;

      this.addSalesTypes(commissionObject);
      this.addAgreementSalesPeople(commissionObject, agreements[i]);

      this.addSalesPay(commissionObject);

      commissionObjects.push(commissionObject);
    }

    let retail = this.reportCompilerState.inputFilesArrays.SALE;
    for (let i = 0; i < retail.length; i++) {
      if (
        retail[i].constructor.name !== "AgreementEvent" &&
        retail[i].commissionType !== "Agreement"
      ) {
        let description = retail[i].name;
        let classNameFound = this.includesClassName(description);
        if (classNameFound) {
          description = description.toUpperCase();
        }
        let commissionObject = new CommissionObject(
          retail[i].location,
          retail[i].date,
          retail[i].price,
          retail[i].clientFirstName,
          retail[i].clientLastName,
          description
        );
        commissionObject.addPreviousPurchase(
          0,
          [],
          "",
          "",
          "New",
          null,
          0
        );
        commissionObject.type = "Retail";
        commissionObject.discount = retail[i].discountPercent;
        commissionObject.notes = retail[i].notes;
        this.addRetailCommissionSalesPeople(commissionObject, retail[i]);
        let bucket = this.findSalesBucketType(commissionObject, "Retail");

        let salesTypeObject = {
          bucketType: bucket.type,
          bucketName: bucket.name,
          statusType: "New",
          reactivation: false,
          sessionConversion: false,
          tier: 1,
          step: null,
          extendedType: ""
        };
        commissionObject.type = salesTypeObject;

        this.addSalesTypes(commissionObject);

        this.addSalesPay(commissionObject);

        commissionObjects.push(commissionObject);
      }
    }

    return commissionObjects;
  }

  findSalesBucketType(commissionObject, implicitType) {
    let salesBuckets = this.reportCompilerState.inputFilesArrays.questions.salesBuckets;

    let bucket = null;
    for (let i = 0; i < salesBuckets.length; i++) {
      let bucketItemNames = salesBuckets[i].itemNames;
      for (let j = 0; j < bucketItemNames.length; j++) {
        if (salesBuckets[i].type === implicitType && commissionObject.description.includes(bucketItemNames[j])) {
          bucket = salesBuckets[i];
        }
      }
    }
    if (bucket === null) { //if no matching names, assign it to the implicit agreement or retail type
      for (let i = 0; i < salesBuckets.length; i++) {
        if (salesBuckets[i].implicitType && salesBuckets[i].type === implicitType) {
          bucket = salesBuckets[i];
        }
      }
    }

    return bucket;
  }

  addExtendedType(commissionObject) {
    let extendedType = commissionObject.type.statusType;
    if (commissionObject.type.reactivation) {
      extendedType += " Reactivation";
    }
    if (commissionObject.type.sessionConversion) {
      extendedType += " Conversion";
    }
    if (commissionObject.type.tier >= 0) {
      extendedType += " Tier" + commissionObject.type.tier;
    }
    if (commissionObject.type.step !== null) {
      extendedType += " Step" + commissionObject.type.step;
    }

    commissionObject.type.extendedType = extendedType;
  }

  addAgreementSalesPeople(commissionObject, agreement) {
    commissionObject.addSalesPerson("close", agreement.salespeople.PrimarySalesperson);
    commissionObject.addSalesPerson("secondary", agreement.salespeople.SecondarySalesperson);
  }

  addSalesTypes(commissionObject) {
    let commissionBucket = this.getCommissionBucket(commissionObject.type.bucketName);

    if (commissionBucket.trackPrevious) {
      //let previousPurchaseAmount = commissionObject.previousPurchases.previousAmount;
      // if(previousPurchaseAmount > 1 && commissionBucket.multiplePreviousAgreementsPayment === "Disregard all but last previous agreement"){
      //   commissionObject.previousPurchases.previousAmount = 1;
      //   //continue
      // }

      let hierarchyTypes = this.findHierarchyTypes(commissionBucket, commissionObject);

      commissionObject.type.statusType = hierarchyTypes.type;
      commissionObject.type.tier = hierarchyTypes.new;
      commissionObject.type.step = hierarchyTypes.steps;
      commissionObject.type.reactivation = hierarchyTypes.reactivation;

      // if(commissionObject.previousPurchases.previousAmount > 0 && commissionBucket.reactivationTimeFrame !== -1){
      //   let reactivationTimeFrameDays = parseFloat(commissionBucket.reactivationTimeFrame.replace("~",""));
      //   let previousPurchaseDate = commissionObject.previousPurchases.previousAgreementCancelDate || commissionObject.previousPurchases.previousAgreementDate;

      //   let currentDate = commissionObject.dateSold;

      //   let currentPurchaseDateObject = new Date(currentDate);
      //   let previousPurchaseDateObject = new Date(previousPurchaseDate);

      //   if(previousPurchaseDate !== ""){
      //     let differenceInTime = currentPurchaseDateObject.getTime() - previousPurchaseDateObject.getTime();
      //     let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      //     if(differenceInDays >= reactivationTimeFrameDays){
      //       if(commissionBucket.reactivationOptions === "Regard as unique 'Returning' type if length is less than reactivationTimeFrame"){
      //         commissionObject.type.reactivation = true;
      //         commissionObject.previousPurchases.previousAmount = 1;
      //         let hierarchyTypes = this.findHierarchyTypes(commissionBucket, commissionObject);

      //         commissionObject.type.statusType = hierarchyTypes.type;
      //         commissionObject.type.tier = hierarchyTypes.new;
      //         commissionObject.type.step = hierarchyTypes.steps;
      //       }else if(commissionBucket.reactivationOptions === "Regard as new if length is less than reactivationTimeFrame"){
      //         commissionObject.type.statusType = "New";
      //         commissionObject.type.reactivation = true;
      //       } else if(commissionBucket.reactivationOptions === "Disregard length between purchases"){
      //         commissionObject.type.reactivation = false;
      //       }
      //     }
      //   } 
      // }
    } else {
      let tier = this.findTier(commissionObject.description, commissionBucket.hierarchy);
      commissionObject.type.statusType = "New";
      commissionObject.type.tier = tier;
    }

    if (commissionBucket.sessionConversion) {
      let sessionConversion = this.isSessionConversion(commissionObject, commissionBucket);
      commissionObject.type.sessionConversion = sessionConversion;
    }

    this.addExtendedType(commissionObject);
  }

  getCommissionBucket(name) {
    let commissionObjects = this.reportCompilerState.inputFilesArrays.questions.salesBuckets;
    for (let i = 0; i < commissionObjects.length; i++) {
      if (commissionObjects[i].name === name) {
        return commissionObjects[i];
      }
    }
    return null;
  }

  isSessionConversion(commissionObject, commissionBucket) {
    const classes = this.reportCompilerState.studiosInformation.classes;
    for (let i = 0; i < classes.length; i++) {
      const classObj = classes[i];

      let correctConversionType = false;
      let conversionTypes = commissionBucket.sessionConversionClassBuckets;
      for (let j = 0; j < conversionTypes.length; j++) {
        if (classObj.type.includes(conversionTypes[j])) {
          correctConversionType = true;
        }
      }
      if (correctConversionType) {
        for (let j = 0; j < classObj.attendeeList.length; j++) {
          const attendee = classObj.attendeeList[j];
          let lengthBetween = classObj.date.getTime() - commissionObject.dateSold.getTime(); //convert this to hours
          let differenceInDays = lengthBetween / (1000 * 60 * 60 * 24);
          let sessionConversionDays = isNaN(commissionBucket.sessionConversionTimeHours) ? parseFloat(commissionBucket.sessionConversionTimeHours.replace("~", "")) : parseFloat(commissionBucket.sessionConversionTimeHours);
          if (
            attendee.name === commissionObject.customer &&
            attendee.signedUpAfterSession &&
            differenceInDays <= sessionConversionDays
          ) {
            commissionObject.sessionConvertedFrom = classObj.name + " - " + classObj.date;
            this.addIntroSpecificSalesPeople(
              commissionObject,
              attendee,
              classObj
            ); //maybe we need this
            return true;
          }
        }
      }
    }
    return false;
  }

  findHierarchyTypes(bucket, commissionObject) {
    let { hierarchy, multiplePreviousAgreementsPayment } = bucket;
    let { previousPurchases, description } = commissionObject;

    //determine interchangeable rate categories for N/A
    let interchangeableRateTypes = this.getInterchangeableRateTypes(bucket);

    let previousPurchaseTier = null, newPurchaseTier = null;
    newPurchaseTier = this.findTier(description, hierarchy);
    previousPurchaseTier = this.findTier(previousPurchases.previousAgreements[0], hierarchy);

    let steps = this.findSteps(previousPurchaseTier, newPurchaseTier);

    let salesType = this.findSalesType(newPurchaseTier, previousPurchaseTier, steps, commissionObject.previousPurchases.previousAmount, multiplePreviousAgreementsPayment);
    let fullType = { type: salesType, previous: previousPurchaseTier, new: newPurchaseTier, steps: steps };
    fullType = this.findReactivationAdjustments(commissionObject, bucket, fullType, commissionObject.previousPurchases.previousAgreementCancelDate || commissionObject.previousPurchases.previousAgreementDate);

    fullType = this.findAlternateSalesType(fullType, commissionObject, bucket.multiplePreviousAgreementsPayment, interchangeableRateTypes);

    return fullType;
  }

  getInterchangeableRateTypes(bucket) {
    let interchangeableRateTypes = [];
    const rateNames = ["Upgrade", "Downgrade", "Renewal"];
    for (const rateName of rateNames) {
      if (bucket.rates[rateName].rate === bucket.rates["N/A"].rate &&
        bucket.rates[rateName].split === bucket.rates["N/A"].split &&
        this.compareArrays(bucket.rates[rateName].salespeople, bucket.rates["N/A"].salespeople) &&
        bucket.rates[rateName].rateType === bucket.rates["N/A"].rateType &&
        bucket.rates[rateName].setupFeeIncluded === bucket.rates["N/A"].setupFeeIncluded
      ) {
        interchangeableRateTypes.push(rateName);
      }
    }
    return interchangeableRateTypes;
  }

  previousAgreementDeepCompareHighestTier(commissionObject) {
    let commissionBucket = this.getCommissionBucket(commissionObject.type.bucketName);
    let hierarchy = commissionBucket.hierarchy;

    let previousAgreements = commissionObject.allPreviousPurchases;
    let highestTier = 0;
    let highestTierAgreement = null;
    for (let i = 0; i < previousAgreements.length; i++) {
      let previousAgreement = previousAgreements[i].agreementName;
      let tier = this.findTier(previousAgreement, hierarchy);
      if (tier > highestTier) {
        highestTier = tier;
        highestTierAgreement = previousAgreement;
      }
    }

    let currentTier = this.findTier(commissionObject.description, hierarchy);
    let steps = this.findSteps(highestTier, currentTier);

    let salesType = this.findSalesType(currentTier, highestTier, steps, 1, null);
    return salesType;
  }

  previousAgreementDeepCompareFullComparison(currentSalesType, commissionObject) {
    let commissionBucket = this.getCommissionBucket(commissionObject.type.bucketName);
    let interchangeableRateTypes = this.getInterchangeableRateTypes(commissionBucket);
    let hierarchy = commissionBucket.hierarchy;

    let previousAgreements = commissionObject.allPreviousPurchases;
    let saleTypeOrdering = [currentSalesType];
    for (let i = 1; i < previousAgreements.length; i++) {
      let previousNumberOfAgreements = previousAgreements.length - i;
      let currentAgreement = previousAgreements[i-1].agreementName;
      let previousAgreement = previousAgreements[i].agreementName;

      let previousPurchaseTier = null, newPurchaseTier = null;
      newPurchaseTier = this.findTier(currentAgreement, hierarchy);
      previousPurchaseTier = this.findTier(previousAgreement, hierarchy);

      let steps = this.findSteps(previousPurchaseTier, newPurchaseTier);

      let salesType = this.findSalesType(newPurchaseTier, previousPurchaseTier, steps, previousNumberOfAgreements, commissionBucket.multiplePreviousAgreementsPayment);
      
      let fullType = { type: salesType, previous: previousPurchaseTier, new: newPurchaseTier, steps: steps };
      fullType = this.findReactivationAdjustments(commissionObject, bucket, fullType, previousAgreements[i].previousAgreementCancelDate || previousAgreements[i].previousAgreementDate);

      fullType = this.findAlternateSalesType(fullType, commissionObject, commissionBucket.multiplePreviousAgreementsPayment, interchangeableRateTypes);

      saleTypeOrdering.push(fullType.type);
    }

    //code to determine what it should be
  }

  findTier(description, hierarchy) {
    let tier = null;

    for (let [key, salesNames] of Object.entries(hierarchy)) {
      for (let i = 0; i < salesNames.length; i++) {
        let name = salesNames[i];
        let isNestedBucket = /^\*.+\*$/.test(name);
        let items = isNestedBucket ? this.getCommissionBucket(name.slice(1, -1)).itemNames : [name];

        for (let j = 0; j < items.length; j++) {
          let item = items[j];
          if (description.includes(item)) {
            tier = parseInt(key);
            break;
          }
        }

        if (tier !== null) break;
      }

      if (tier !== null) break;
    }

    return tier;
  }


  findSteps(previousTier, newTier) {
    let steps = null
    if (previousTier !== null && newTier !== null) {
      steps = newTier - previousTier;
    }
    return steps;
  }

  findSalesType(newPurchaseTier, previousPurchaseTier, steps, previousPurchaseAmount, multiplePreviousAgreementsPayment) {
    let salesType = "New";
    if (newPurchaseTier !== null &&
      (previousPurchaseAmount === 0
        || multiplePreviousAgreementsPayment === "Disregard all previous agreements"
        || ((multiplePreviousAgreementsPayment === "Disregard all but last previous agreement" || previousPurchaseAmount === 1) && previousPurchaseTier === 0 && steps > 0))) {
      salesType = "New";
    } else if (steps > 0) {
      salesType = "Upgrade";
    } else if (steps < 0) {
      salesType = "Downgrade";
    } else if (steps === 0) {
      salesType = "Renewal";
    } else if (steps === null) {
      salesType = "N/A";
    }

    return salesType;
  }

  findAlternateSalesType(fullType, commissionObject, multiplePreviousAgreementsPayment, interchangeableRateTypes) {
    let salesType = fullType.type;
    return fullType;
    if(fullType.reactivation){
      return fullType;
    }
    if (commissionObject.previousPurchases.previousAmount > 1 && commissionObject.allPreviousPurchases.length > 0 && multiplePreviousAgreementsPayment === "Take into account all previous agreements & use highest tier agreement for base") {
      //add logic to look at all previously sold items
      salesType = this.previousAgreementDeepCompareHighestTier(commissionObject);
      //if not (N/A)
      if (!interchangeableRateTypes.includes(salesType)) {
        salesType = "N/A";
      }
    } else if (commissionObject.previousPurchases.previousAmount > 1 && commissionObject.allPreviousPurchases.length > 0 && multiplePreviousAgreementsPayment === "Take into account all previous agreements & let PayWell AI decided if its commissionable") {
      //add logic to look at all previously sold items
      //salesType = this.previousAgreementDeepCompareHighestTier(commissionObject);
      salesType = this.previousAgreementDeepCompareFullComparison(salesType, commissionObject);
      //if not (N/A)
      if (!interchangeableRateTypes.includes(salesType)) {
        salesType = "N/A";
      }
    }

    fullType.type = salesType;
    return fullType;
  }

  findReactivationAdjustments(commissionObject, commissionBucket, typeObject, previousAgreementCancelDate) {
    //**need to determine how previous agreement is provided**
    // let typeObject = commissionObject.type;
    if (commissionBucket.reactivationTimeFrame !== -1) {
      let reactivationTimeFrameDays = parseFloat(commissionBucket.reactivationTimeFrame.replace("~", ""));
      let previousPurchaseDate = previousAgreementCancelDate;

      let currentDate = commissionObject.dateSold;

      let currentPurchaseDateObject = new Date(currentDate);
      let previousPurchaseDateObject = new Date(previousPurchaseDate);

      if (previousAgreementCancelDate !== null && previousPurchaseDate !== "") {
        let differenceInTime = currentPurchaseDateObject.getTime() - previousPurchaseDateObject.getTime();
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);
        if (differenceInDays >= reactivationTimeFrameDays) {
          if (commissionBucket.reactivationOptions === "Regard as unique 'Returning' type if length is less than reactivationTimeFrame") {

            let previousAgreement = commissionObject?.previousPurchases?.previousPurchase;
            if(commissionObject?.allPreviousPurchases && commissionObject?.allPreviousPurchases?.length > 0){
              previousAgreement = commissionObject.allPreviousPurchases[0].agreementName;
            }

            let newTier = this.findTier(commissionObject.description, commissionBucket.hierarchy);
            let previousTier = this.findTier(previousAgreement, commissionBucket.hierarchy);

            let steps = this.findSteps(previousTier, newTier);
            let newSalesType = this.findSalesType(newTier, previousTier, steps, 1, null);

            typeObject.type = newSalesType;
            typeObject.reactivation = true;
            typeObject.tier = newTier;
            typeObject.step = steps;
          } else if (commissionBucket.reactivationOptions === "Regard as new if length is less than reactivationTimeFrame") {
            typeObject.type = "New";
            typeObject.reactivation = true;
          } else if (commissionBucket.reactivationOptions === "Disregard length between purchases") {
            typeObject.reactivation = false;
          }
        }
      }
    }

    return typeObject;
  }


  compareArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }

  addSalesPay(commissionObject) {
    let type = commissionObject.type;
    let bucket = this.getCommissionBucket(type.bucketName);

    let payRateName = this.findNearestPayRate(commissionObject);
    let payRate = bucket.rates[payRateName];
    commissionObject.type.bucketRate = payRateName;

    if (commissionObject.type.tier === 0) {
      payRate = {
        rateType: "Fixed",
        rate: 0,
        setupFeeIncluded: false,
        split: true,
        salespeople: ["Close"]
      };
    }

    //add discount logic
    let highestDiscount = 0;

    const entries = Object.entries(bucket.discountPercentages);
    for (let i = 0; i < entries.length; i++) {
      const [discountPercentage, value] = entries[i];
      let discountAmount = parseFloat(discountPercentage.replace('%', ''));
      let discount = parseFloat(commissionObject.discount) * 100;
      if (discountAmount < discount && discountAmount > highestDiscount) {
        payRate = value;
        highestDiscount = discountAmount;
        commissionObject.comment.push("Discount: " + discount + "%");
      }
    }

    if (commissionObject.previousPurchases.previousAmount > 1 && bucket.multiplePreviousAgreementsPayment === "Do Not Pay") {
      payRate = {
        rateType: "Fixed",
        rate: 0,
        setupFeeIncluded: false,
        split: true,
        salespeople: ["Close"]
      };
    }

    if (commissionObject.payment <= 0 && bucket.unpaidItemOptions === "Pay a Different Rate") {
      payRate = bucket.unpaidItemRate;
    } else if (commissionObject.payment <= 0 && bucket.unpaidItemOptions === "Do Not Pay") {
      payRate = {
        rateType: "Fixed",
        rate: 0,
        setupFeeIncluded: false,
        split: true,
        salespeople: ["Close"]
      };
    }

    if (commissionObject?.postDated && bucket.postDatedPayment === "Do Not Pay") {
      payRate = {
        rateType: "Fixed",
        rate: 0,
        setupFeeIncluded: false,
        split: true,
        salespeople: ["Close"]
      };
    }

    if (this.isStaff(commissionObject.customer) && !bucket.commissionStaffBought) {
      payRate = {
        rateType: "Fixed",
        rate: 0,
        setupFeeIncluded: false,
        split: true,
        salespeople: ["Close"]
      };
    }

    let exceptionInfo = this.checkCommissionExceptions(commissionObject);
    let exceptionFound = exceptionInfo.exception;
    let exceptionComment = exceptionInfo.comment;
    if (
      exceptionFound !== null
    ) {
      if (exceptionFound.rateFrequency === "Do Not Pay") {
        payRate = {
          rateType: "Fixed",
          rate: 0,
          setupFeeIncluded: false,
          split: true,
          salespeople: ["Close"]
        };
      } else if (exceptionFound.rateFrequency === "Pay As Normal" && payRate.rate !== 0) {
        payRate = {
          rateType: exceptionFound.rate > 1 ? "Fixed" : "PercentFull",
          rate: exceptionFound.rate,
          setupFeeIncluded: false,
          split: exceptionFound.split,
          salespeople: exceptionFound.salespeople
        };
      } else if (exceptionFound.rateFrequency === "Always Pay") {
        payRate = {
          rateType: exceptionFound.rate > 1 ? "Fixed" : "PercentFull",
          rate: exceptionFound.rate,
          setupFeeIncluded: false,
          split: exceptionFound.split,
          salespeople: exceptionFound.salespeople
        };
      }

      commissionObject.comment.push(exceptionComment);
    }

    let payPool = 0;
    if (payRate.rateType === "Fixed") {
      payPool = payRate.rate;
    } else if (payRate.rateType === "PercentFull") {
      payPool = (payRate.rate / 100) * commissionObject.payment;
    } else if (payRate.rateType === "PercentPortion") {
      payPool = payRate.rate * commissionObject.payment;
    } else if (payRate.rateType === "Delta") {
      let previousAmount = commissionObject.previousPurchases?.previousAgreementPay ?? null;
      if(previousAmount !== null){
        let payAmount = (commissionObject.payment - parseFloat((commissionObject.previousPurchases?.previousAgreementPay ?? 0)));
        if(payAmount > 0){
          payPool = (payRate.rate / 100) * payAmount;
          commissionObject.comment.push("Delta: $" + payAmount);
        }
      }
    }

    let paySplit = [0, 0, 0, 0];
    let split = 0;
    if (payRate.salespeople.includes("Open") && commissionObject.salespeople.open) {
      if (this.checkStaff(commissionObject.salespeople.open, bucket.staffNamesPaidForThisBucket, bucket.staffTypesPaidForThisBucket)) {
        paySplit[0] = payPool;
        split++;
      }
    }
    if (payRate.salespeople.includes("Close") && commissionObject.salespeople.close) {
      if (this.checkStaff(commissionObject.salespeople.close, bucket.staffNamesPaidForThisBucket, bucket.staffTypesPaidForThisBucket)) {
        paySplit[1] = payPool;
        split++;
      }
    }
    if (payRate.salespeople.includes("Instructor") && commissionObject.salespeople.instructor) {
      if (this.checkStaff(commissionObject.salespeople.instructor, bucket.staffNamesPaidForThisBucket, bucket.staffTypesPaidForThisBucket)) {
        paySplit[2] = payPool;
        split++;
      }
    }
    if (payRate.salespeople.includes("Second") && commissionObject.salespeople.secondary) {
      if (this.checkStaff(commissionObject.salespeople.secondary, bucket.staffNamesPaidForThisBucket, bucket.staffTypesPaidForThisBucket)) {
        paySplit[3] = payPool;
        split++;
      }
    }

    for (let p = 0; p < paySplit.length; p++) {
      if (paySplit[p] > 0) {
        paySplit[p] = paySplit[p] / split;
      }
    }


    //do with the rest of the salespeople
    //add staff override pay logic
    if (commissionObject.salespeople.open) {
      let staffObject = findStaffObject(commissionObject.salespeople.open, this.reportCompilerState);
      let overrideRate = this.findNearestStaffPayRate(commissionObject, staffObject);
      if (overrideRate !== null) {
        if (overrideRate < 1) {
          paySplit[0] = overrideRate * commissionObject.payment;
        } else {
          paySplit[0] = overrideRate;
        }
      }
      //find nearest staff override amount (Uses extended name logic)
      //if its a fixed amount override the pay split [0], if percentage, give percentage of original pay
    }
    if (commissionObject.salespeople.close) {
      let staffObject = findStaffObject(commissionObject.salespeople.close, this.reportCompilerState);
      let overrideRate = this.findNearestStaffPayRate(commissionObject, staffObject);
      if (overrideRate !== null) {
        if (overrideRate < 1) {
          paySplit[1] = overrideRate * commissionObject.payment;
        } else {
          paySplit[1] = overrideRate;
        }
      }
    }
    if (commissionObject.salespeople.instructor) {
      let staffObject = findStaffObject(commissionObject.salespeople.instructor, this.reportCompilerState);
      let overrideRate = this.findNearestStaffPayRate(commissionObject, staffObject);
      if (overrideRate !== null) {
        if (overrideRate < 1) {
          paySplit[2] = overrideRate * commissionObject.payment;
        } else {
          paySplit[2] = overrideRate;
        }
      }
    }
    if (commissionObject.salespeople.secondary) {
      let staffObject = findStaffObject(commissionObject.salespeople.secondary, this.reportCompilerState);
      let overrideRate = this.findNearestStaffPayRate(commissionObject, staffObject);
      if (overrideRate !== null) {
        if (overrideRate < 1) {
          paySplit[3] = overrideRate * commissionObject.payment;
        } else {
          paySplit[3] = overrideRate;
        }
      }
    }

    commissionObject.salespay.open = paySplit[0];
    commissionObject.salespay.close = paySplit[1];
    commissionObject.salespay.instructor = paySplit[2];
    commissionObject.salespay.secondary = paySplit[3];

    if (commissionObject.type.sessionConversion) {
      commissionObject.salespay.instructor = isNaN(bucket.sessionConversionInstructorBonus) ? parseFloat(bucket.sessionConversionInstructorBonus.replace("~", "")) : parseFloat(bucket.sessionConversionInstructorBonus);
    }

    return;
  }

  findNearestPayRate(commissionObject) {
    let type = commissionObject.type;
    let rates = this.getCommissionBucket(type.bucketName).rates;
  
    let nearest = type.extendedType;
    let nearestDistance = 0;
  
    // Convert the keys of rates to an array
    let rateKeys = Object.keys(rates);
  
    // Iterate over the array using a traditional for loop
    for (let i = 0; i < rateKeys.length; i++) {
      let key = rateKeys[i];
      let matchingDistance = 0;
  
      if (key.includes(type.statusType)) {
        if (type.reactivation && key.includes("Reactivation")) {
          matchingDistance++;
        } else if (!type.reactivation && !key.includes("Reactivation")) {
          matchingDistance++;
        }
  
        if (type.sessionConversion && key.includes("Conversion")) {
          matchingDistance++;
        } else if (!type.sessionConversion && !key.includes("Conversion")) {
          matchingDistance++;
        }
  
        if (!key.includes("Tier")) {
          matchingDistance++;
        } else if (type.tier === parseInt(key.charAt(key.indexOf("Tier") + 4))) {
          matchingDistance += 2; // Increment twice for a perfect match
        } else if (type.tier !== parseInt(key.charAt(key.indexOf("Tier") + 4))) {
          matchingDistance = -2; // Penalize non-match
        }
  
        if (!key.includes("Step")) {
          matchingDistance++;
        } else if (type.step === parseInt(key.charAt(key.indexOf("Step") + 4))) {
          matchingDistance += 2; // Increment twice for a perfect match
        } else if (type.step !== parseInt(key.charAt(key.indexOf("Step") + 4))) {
          matchingDistance = -2; // Penalize non-match
        }
      }
  
      if (matchingDistance > nearestDistance) {
        nearestDistance = matchingDistance;
        nearest = key;
      }
    }
  
    return nearest;
  }
  

  findNearestStaffPayRate(commissionObject, staffObject) {
    if (!staffObject || staffObject === null) {
      return null;
    }
    //let type = commissionObject.type;
    let rateUsed = commissionObject.type.bucketName + "-" + commissionObject.type.bucketRate;
    let staffLocationItems = staffObject.infoObject[commissionObject.location] || staffObject.infoObject["ALL"] || {};
    let rates = Object.keys(staffLocationItems).filter((key) => key.includes("-"));

    //let nearest = null;

    //let nearestDistance = 0;
    for (let i = 0; i < rates.length; i++) {
      let rate = rates[i];
      if(rateUsed === rate){
        if(staffLocationItems && staffLocationItems[rate]){
          return staffLocationItems[rate];
        }
      }
    }

      // let matchingDistance = 0;
      // if (rate.includes(type.bucketName) && rate.includes(type.statusType)) {
      //   if (type.reactivation && rate.includes("Reactivation")) {
      //     matchingDistance++;
      //   }
      //   if (type.sessionConversion && rate.includes("Conversion")) {
      //     matchingDistance++;
      //   }
      //   if (type.tier === parseInt(rate.charAt(rate.indexOf("Tier") + 4))) {
      //     matchingDistance++;
      //   }
      //   if (type.step === parseInt(rate.charAt(rate.indexOf("Step") + 4))) {
      //     matchingDistance++;
      //   }
      // }
      // let staffOverrideRate = staffObject[rate + "CommissionPercentage"];
      // if (staffOverrideRate && staffOverrideRate !== null && matchingDistance > nearestDistance) {
      //   nearestDistance = matchingDistance;
      //   nearest = rate;
      // }
    //}

    return null;
  }

  checkStaff(staffName, names, titles) {
    let staffArray = this.reportCompilerState.studiosInformation.staffArray;

    let staffObject = findStaffObject(staffName, this.reportCompilerState);

    if (staffObject === false) {
      return false;
    }
    if (names.length === 0 && titles.length === 0) {
      return true;
    }

    //for(let i = 0; i < staffArray.length; i++){
    for (let j = 0; j < names.length; j++) {
      if (staffObject.isNamed(names[j])) {
        return true;
      }
    }

    for (let k = 0; k < titles.length; k++) {
      if (staffObject.getAllTypes().includes(titles[k])) {
        return true;
      }
    }
    //}

    return false;
  }

  /**
   * Splits a JSON object into batches of a specified size.
   *
   * @param {Object} jsonObject - The JSON object to be split.
   * @param {number} batchSize - The number of items in each batch.
   * @returns {Array} An array containing the split JSON objects in the specified format.
   */
  splitJsonObject(jsonObject, batchSize) {
    const keys = Object.keys(jsonObject);
    const splitObjects = [];

    for (let i = 0; i < keys.length; i += batchSize) {
      const end = i + batchSize;
      const splitKeys = keys.slice(i, end);

      const split = splitKeys.map(key => jsonObject[key]);
      splitObjects.push({ searchSets: split });
    }

    return splitObjects;
  }

  async compilePreviousAgreementData() {
    const maxBatchSize = 20;

    // CALL FUNCTION TO GENERATE SEARCH SETS TO PASS INTO API
    let testEvent = this.grabMultiAgreements();

    // console.log("Total Search Sets: ", testEvent.body.searchSets.length);
    // Split the search sets based on the specified number of search sets per request
    let split = this.splitJsonObject(testEvent.body.searchSets, maxBatchSize);

    // console.log(`Total number of batches to process: ${split.length}`);
    const token = await getFirebaseJwtToken();

    // Record start time
    const startTime = Date.now();

    // Create an array of promises for each batch
    const batchPromises = split.map((currentBatch, index) => {
        // console.log(`Initiating batch ${index + 1}/${split.length}:`, currentBatch);
        return this.fetchData(token,currentBatch)
            .then(data => {
                // console.log(`Data from batch ${index + 1}/${split.length}: `, data);
                return data;
            })
            .catch(error => {
              console.log('Error fetching data:', error);
                //console.error(`Error processing batch ${index + 1}/${split.length}:`, error);
                throw error;  // Re-throw the error to ensure Promise.all fails if any batch fails
            });
    });

    // Wait for all batches to complete
    try {
        const allData = await Promise.all(batchPromises);
        console.log('All batches processed.');

        // Record end time and calculate total duration
        const endTime = Date.now();
        const totalDuration = (endTime - startTime) / 1000;  // Duration in seconds
        // console.log('Total Duration:', totalDuration);

        // Merge all the results into one response
        const mergedValidatedResults = allData.flatMap(batch => batch.validatedResults);
        const mergedAllResults = allData.flatMap(batch => batch.allResults);

        // console.log('Merged Validated Results:', mergedValidatedResults);
        // console.log('Merged All Results:', mergedAllResults);

        // console.log('Final Result: ', {
        //     validatedResults: mergedValidatedResults,
        //     allResults: mergedAllResults,
        //     totalDuration: totalDuration
        // });

        // Handle the merged results
        this.addAllPreviousAgreements(mergedValidatedResults);

        // Adjust other items if necessary
        this.adjustMultiAgreementCommissionItems();

        // return {
        //     validatedResults: mergedValidatedResults,
        //     allResults: mergedAllResults,
        //     totalDuration: totalDuration
        // };
    } catch (error) {
        //console.error('Error processing batches:', error);
        // Handle the error appropriately
        throw error;
    }
}



  fetchData = async (token, body) => {
    try {

      const apiName = 'paywellAPIResource'; // Replace with your API name
      const path = '/queryReportData'; // Replace with your endpoint path

      //console.log(body);
      const init = {
        body: body,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await API.post(apiName, path, init);
      return response;
    } catch (error) {
      //console.log('Error fetching data:', error);
      //console.error(error);
    }
  };

grabMultiAgreements = () => {
  let testEvents = {
    "body": {
      "searchSets": []
    }
  };
  let commissionItems = this.reportCompilerState.studiosInformation.commissions;
  for(let i = 0; i < commissionItems.length; i++) {
    if(commissionItems[i].previousPurchases.previousAmount === 0){
      continue;
    }
    if(commissionItems[i].type.tier === 0){
      continue;
    }
    let commissionItem = commissionItems[i];
    let location = commissionItem.location;
    let clientFirst = commissionItem.clientFirstName;
    let clientLast = commissionItem.clientLastName;
    let searchSet = {
      "Location Name": location,
      reportType: "agree",
      "Client First": clientFirst,
      "Client Last": clientLast
    }
    testEvents["body"]["searchSets"].push(searchSet);
  }

    return testEvents;
  }


  addAllPreviousAgreements = (res) => {
    let commissionItems = this.reportCompilerState.studiosInformation.commissions;
    for (let i = 0; i < res.length; i++) {
      let seatchSet = res[i].searchSet;
      let results = res[i].results;
      for (let j = 0; j < commissionItems.length; j++) {
        let commissionItem = commissionItems[j];
        let location = commissionItem.location;
        let clientFirst = commissionItem.clientFirstName;
        let clientLast = commissionItem.clientLastName;

        if (seatchSet["Location Name"] === location && seatchSet["Client First"] === clientFirst && seatchSet["Client Last"] === clientLast) {
          this.addPreviousAgreements(commissionItem, results);
        }
      }
    }
  }

  addPreviousAgreements = (commissionItem, results) => {
    //let addedMostRecent = false;
    const uniqueAgreements = new Set();  // Set to track unique identifier strings

    for (let i = 0; i < results.length; i++) {
        let result = results[i];
        let prevAgreementDate = new Date(result["Agreed Date"]);
        if (prevAgreementDate <= commissionItem.dateSold &&
            result.Description !== commissionItem.description &&
            commissionItem.previousPurchases.previousAmount !== result['#Previous Agreements']
        ) {
            let cancelDate = this.findCancelDate(result.Description);
            let agreementIdentifier = `${result.Description}-${prevAgreementDate.toISOString()}`; // Unique identifier

            if (!uniqueAgreements.has(agreementIdentifier)) {  // Check if it's already added
                uniqueAgreements.add(agreementIdentifier);  // Mark this identifier as added
                let previousAgreement = {
                    payment: result["$ Paid Upfront"],
                    agreementName: result.Description,
                    agreementDate: prevAgreementDate,
                    previousAgreementAmount: result['#Previous Agreements'],
                    previousAgreementName: result["Previous Agreement Detail"],
                    previousAgreementDate: result["Previous Agreed Date"], //here
                    previousAgreementCancelDate: cancelDate,
                    previousAgreementStatus: result["Current Status"],
                }
                commissionItem.allPreviousPurchases.push(previousAgreement);
            }
        }
    }
    if(commissionItem.allPreviousPurchases.length > 0){
      commissionItem.allPreviousPurchases.sort((a, b) => new Date(b.agreementDate) - new Date(a.agreementDate));

      commissionItem.previousPurchases.previousAgreementDate = this.formatDate(commissionItem.allPreviousPurchases[0].agreementDate);
      commissionItem.previousPurchases.previousAgreementPay = commissionItem.allPreviousPurchases[0].payment;
      commissionItem.previousPurchases.previousAgreementCancelDate = commissionItem.allPreviousPurchases[0].previousAgreementCancelDate;
    }
  }

  formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [month, day, year].join('/');
  }

  findCancelDate = (description) => {
    let cancelDate = null
    if (description.includes("[CANCELLED") || description.includes("[SCHEDULED CANCEL")) {
      // Extract the content within the brackets
      let contentWithinBracketsMatch = description.match(/\[(.*?)\]/);
      if (contentWithinBracketsMatch && contentWithinBracketsMatch[1]) {
        // Find the date within the extracted content
        let dateMatch = contentWithinBracketsMatch[1].match(/(\d{1,2}\/\d{1,2}\/\d{4})/);
        if (dateMatch && dateMatch[1]) {
          // Convert the extracted date string to a Date object
          cancelDate = new Date(dateMatch[1]);
        }
      }
    }
    return cancelDate;
  }

  adjustMultiAgreementCommissionItems = () => {
    for (let i = 0; i < this.reportCompilerState.studiosInformation.commissions.length; i++) {
      let commissionItem = this.reportCompilerState.studiosInformation.commissions[i];
      if (commissionItem.allPreviousPurchases.length > 0) {
        this.addSalesTypes(commissionItem);

        this.addSalesPay(commissionItem);
      }
    }
  }
}
