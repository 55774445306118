import Typography from "@mui/material/Typography";
import SelectorBox from "../../Components/SelectorBox/SelectorBox";
import { Stack, Divider, Box } from "@mui/material";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { RevertList } from "../../Components/RevertList";
import BooleanToggleElement from "../../Components/BooleanToggle";
import { useAuth } from "../../../contexts/AuthContext";
import { getUrlVariableValue } from "../../../utility-functions/utility-functions.js";
import { Skeleton } from "@mui/material";
import InputBoxElement from "../../Components/InputBox/InputBox";

export default function TimeForm() {
  const { getUID } = useAuth();

  const { settings, dispatch, changes, setChanges } = useSettingsContext();

  const handleUpdate = (value, id) => {
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      settings[getUrlVariableValue("settingsId")].timeSettings.general[id],
      value
    );
    setChanges(newRev);

    dispatch({
      type: "UPDATE_GENERAL_TIME_SETTINGS_WITH_KEY",
      settingsId: getUrlVariableValue("settingsId"),
      uid: getUID(),
      key: id,
      value: value,
    });
  };

  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        {settings[getUrlVariableValue("settingsId")].generalSettings.timeName +
          " Clock"}
      </Typography>
      <Divider className="settings-page__divider" />

      <Stack className="settings-page__stack" direction="column" spacing={3}>
        <Typography>
          {"Which " +
            settings[getUrlVariableValue("settingsId")].generalSettings
              .timeName +
            " clock file(s) do you use:"}
        </Typography>
        <SelectorBox
          className="selector-box__select"
          id="useTimeClock"
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general
              .useTimeClock
          }
          size={350}
          options={[
            "ClubReady Time Clock Summary",
            "ClubReady Time Punch Detail",
            "WhenIWork Timesheet",
            "WhenIWork Detail Timesheet",
            "Sling Timesheet",
            "Sling Basic Timesheet",
            "Prospr Timesheet",
            "Gusto Timesheet",
            "Paycom Timesheet",
            "Deputy Timesheet",
            "Deputy Detail Timesheet"
          ]}
          placeholder="None"
          label="Time File"
          name="Time File"
        ></SelectorBox>
        
        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Display Punch Detail in Staff Tabs (if applicable)
        </Typography>
        <BooleanToggleElement
          onChange={handleUpdate}
          default={
            settings[getUrlVariableValue("settingsId")].timeSettings.general
              .punchDetailInStaffTabs
          }
          id="punchDetailInStaffTabs"
        ></BooleanToggleElement>

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Does the system automatically clock out staff at a certain time?
        </Typography>
        <InputBoxElement
            id="systemClockOutTime"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime 
              ? settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime.includes("text~") ? settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime : "text~" + settings[getUrlVariableValue("settingsId")].timeSettings.general?.systemClockOutTime 
              : "text~"
            }
            onChange={handleUpdate}
            label="System Clock Out Time"
            types={["text"]}
          />

        <Divider className="settings-page__divider settings-page__divider--light" />
        <Typography>
          Do you require meal breaks? If so, what is the minimum time required:
        </Typography>
        <InputBoxElement
            id="mealBreakFlag"
            default={
              settings[getUrlVariableValue("settingsId")].timeSettings.general?.mealBreakFlag || "hour~-1"
            }
            onChange={handleUpdate}
            label="Meal Break Violation"
            types={["time"]}
          />
      </Stack>
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
