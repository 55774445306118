import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "@mui/material/Tooltip";
import { objFieldChange } from "./utils.js";
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { brands } from "../StudioSettings/CommonVariables";
import {
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { RevertList } from "../Components/RevertList";
import { useSettingsContext } from "../../contexts/SettingsContext";
import { useGridApiContext, useGridApiRef } from "@mui/x-data-grid";
import { useAuth } from "../../contexts/AuthContext";
import "./staffSettings.scss";
import AttributeListBox from "../Components/AttributeListBox";
import { MultipleSelectPlaceholder } from "../Components/SelectorBox/SelectorBox";
import { convertInputBoxValue } from "../Components/InputBox/InputBox";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import { getUrlVariableValue } from "../../utility-functions/utility-functions.js";
import { Skeleton, Divider } from "@mui/material";
import XLSX from "@sheet/core";
import { findBrand, createNewStaffObject } from "./utils.js";
import AddStaffDialogSettings from "./AddStaffDialogSettings.js";
// tab for selecting which columns to view
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="settings-page__tab-panel-inner">
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const attributeArrayRead = (valueArray) => {
  if (valueArray.length === 0) {
    return "-";
  }
  return valueArray.join(", ");
};

const locationsArrayRead = (valueArray) => {
  if (valueArray.length === 0) {
    return "0 Locations Selected";
  } else if (valueArray.indexOf("All Studios") > -1) {
    return "All Studios";
  } else if (valueArray.length > 3) {
    return valueArray.slice(0, 2).join(", ") + ", +" + (valueArray.length - 3);
  }
  return valueArray.join(", ");
};

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 1,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },

  "& .MuiDataGrid-cell:hover": {
    color: "primary.main",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: "inherit",
  width: "100%",
  border: 1,
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1
      })`,
    color: theme.palette.error.main,
  },
}));

const validateName = (name) => {
  // Check if the name is empty or only contains white spaces
  if (!name || name.trim() === "") {
    return "Staff name cannot be empty";
  }

  // Check if the name exceeds 31 characters
  if (name.length > 31) {
    return "Name cannot be longer than 31 characters";
  }

  // Define a regular expression for invalid characters
  // The following characters are restricted due to Excel's limitations:
  // Colon (:), Backslash (\), Forward Slash (/), Asterisk (*),
  // Question Mark (?), Double Quotes ("), Less Than (<),
  // Greater Than (>), Pipe (|), Square Brackets ([ and ]),
  // and Single Quote (').
  const invalidChars = /[\\/:*?"<>|\[\]']/;
  if (invalidChars.test(name)) {
    // If any invalid character is found, return an error message
    return "Name contains invalid characters (: \\ / * ? \" < > | [ ] ' )";
  }

  // Return null if no errors are found
  return null;
};

export default function StaffSettings() {
  const { settings, dispatch, changes, setChanges } = useSettingsContext();
  const { getUID } = useAuth();

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const [editingRowId, setEditingRowId] = useState(0);
  const [editingCellField, setEditingCellField] = useState("");
  const [rowModesModel, setRowModesModel] = React.useState({});
  const apiRef = useGridApiRef();
  const [ratesColumns, setRatesColumns] = useState(
    buildColumns("hourlyRate", null)
  );

  const [addStaffModal, setAddStaffModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleAddStaffDialogSave = (formValues) => {
    const settingsId = getUrlVariableValue("settingsId");
    const { classSettings, generalSettings } = settings[settingsId];
    const { newStaffObj, id } = createNewStaffObject(
      settings,
      getUID,
      classSettings.classBuckets,
      generalSettings
    );

    Object.entries(formValues).forEach(([key, value]) => {
      newStaffObj[key] = value;
    });
    newStaffObj.isNew = false;
    newStaffObj.updated = true;

    dispatch({
      type: "ADD_STAFF_SETTINGS_STAFF_MEMBER",
      newStaffId: id,
      newStaff: newStaffObj,
    });

    let newRev = new RevertList(changes.getValues());
    newRev.addElement(
      id,
      null,
      newStaffObj
    );
    setChanges(newRev);
    setAddStaffModal(false);
  };

  const handleAddStaffDialogClose = () => {
    setAddStaffModal(false);
  };

  // Tab hook
  const [pageTabsValue, setPageTabsValue] = React.useState(0);
  const [tableTabsValue, setTableTabsValue] = React.useState(0);

  const actionInfoColumn = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",

      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <ConfirmationDialog
                component={"h3"}
                confirmTitle={`Delete Staff ${row.name}`}
                confirmDescription={`Are you sure you want to delete staff ${row.name}`}
                confirmButtonColor="error"
                confirmButtonText="Delete"
                icon={<DeleteIcon />}
                onConfirm={handleDeleteClick(row.id)}
              />
            }
            label="Delete"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const actionRateColumn = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",

      cellClassName: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            icon={
              <ConfirmationDialog
                confirmTitle={`Delete Staff ${row.name}`}
                confirmDescription={`Are you sure you want to delete staff ${row.name}`}
                confirmButtonColor="error"
                confirmButtonText="Delete"
                icon={<DeleteIcon />}
                onConfirm={handleDeleteClick(row.id)}
              />
            }
            label="Delete"
            color="inherit"
          />,
        ];
      },
    },
  ];


  const handleTableTabsChange = React.useCallback((event, newValue) => {
    if (isEditing) {
      return;
    }
    setTableTabsValue(newValue);

    const memoizedColumns = (() => {
      if (newValue === 0) {
        return buildColumns("hourlyRate", null);
      } else {
        let buckets = Object.keys(
          settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
        ).sort();
        for (let i = 0; i < buckets.length; i++) {
          if (newValue === i + 1) {
            let rateName =
              buckets[i].charAt(0).toLowerCase() + buckets[i].slice(1) + "Rate";
            return buildColumns(
              rateName,
              settings[getUrlVariableValue("settingsId")].classSettings.classBuckets[buckets[i]]
            );
          }
        }
        return buildColumns("commissionRate", null);
      }
    })();


    setRatesColumns(memoizedColumns);
  }, [isEditing, settings]);


  const handleChangePageTabs = (event, tabIndex) => {

    if (isEditing) {
      return;
    }
    setPageTabsValue(tabIndex);
    // if (!editingRowId) {
    //   setPageTabsValue(tabIndex);
    // } else {
    //   setSnackbar({
    //     children: "Finishing editing before moving to the next tab",
    //     severity: "error",
    //   });
    // }
  };

  const handleCellEditStart = (params) => {
    setEditingRowId(params.id);
    setIsEditing(true);
    const { id, field } = params;
    const cellMode = apiRef.current.getCellMode(id, field);
    if (field !== "actions" && cellMode == "view") {
      apiRef.current.startCellEditMode({
        id: id,
        field: field,
      });
    }
  };
  const handleCellEditStop = (params, event) => {
    const { id, field } = params;
    apiRef.current.stopCellEditMode({ id, field });
    setIsEditing(false);
  };

  const handleDeleteClick = (id) => async () => {
    let prevVal = settings.staff.staff[id];
    let newVal = { ...prevVal, status: "delete" };
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(id, prevVal, newVal);
    setChanges(newRev);
    dispatch({
      type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER_STATUS",
      uid: getUID(),
      id: id,
      status: "delete",
    });
    setIsEditing(false);
  };

  const isWarning = (staffRow) => {
    if (staffRow.name.length === 0 && staffRow.isNew) {
      return "Staff must have name";
    } else if (!staffRow.name.includes(" ") && staffRow.isNew) {
      return "Staff name is usually be in format 'First' 'Last'";
    }

    let staffArray = Object.values(settings.staff.staff);
    for (let i = 0; i < staffArray.length; i++) {
      if (
        staffRow.name === staffArray[i].name &&
        staffRow.id !== staffArray[i].id
      ) {
        return "Already have staff with name: " + staffRow.name;
      }
    }
    return null;
  };

  const checkRequiredFields = (staffRow) => {
    let staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
    //let staffFields = settings.staff.generalSettings.staffFields;
    for (let i = 0; i < staffFields.length; i++) {
      if (staffFields[i].required && (!staffRow[staffFields[i].field])) {
        return staffFields[i].name;
      }
    }
    return null;
  };

  const processRowUpdate = async (newRow) => {
    const prevRow = settings.staff.staff[newRow.id];
    const updatedField = objFieldChange(prevRow, newRow);


    //Check if 'name' field is empty
    if (!newRow.name || newRow.name.trim() === "") {
      throw new Error("Staff name cannot be empty"); // Prevent the row update
    }


    const updatedRow = {
      ...newRow,
      isNew: false,
      updated: true,
      status: newRow.status ? newRow.status : "update",
      statusField: updatedField,
    };

    let prevVal = settings.staff.staff[updatedRow.id];
    let newRev = new RevertList(changes.getValues());
    newRev.addElement(updatedRow.id, prevVal, updatedRow);
    setChanges(newRev);

    dispatch({
      type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER",
      uid: getUID(),
      id: updatedRow.id,
      newStaff: updatedRow,
    });

    // Check for other warnings
    let warningMessage = isWarning(updatedRow);
    if (warningMessage !== null) {
      setSnackbar({ children: warningMessage, severity: "warning" });
    }

    return updatedRow;
  };

  const processRowUpdateError = (error) => {
    console.error(error);
    if (error) {
      setSnackbar({ children: error.message, severity: "error" });
    } else {
      setSnackbar(null);
    }
  };


  const EditToolbar = React.memo((props) => {


    const handleClick = () => {
      // const settingsId = getUrlVariableValue("settingsId");
      // const { classSettings, generalSettings } = settings[settingsId];
      // const { newStaffObj, id } = createNewStaffObject(
      //   settings,
      //   getUID,
      //   classSettings.classBuckets,
      //   generalSettings
      // );

      setAddStaffModal(true);

      // dispatch({
      //   type: "ADD_STAFF_SETTINGS_STAFF_MEMBER",
      //   newStaffId: id,
      //   newStaff: newStaffObj,
      // });

      // setTimeout(() => {
      //   apiRef.current.startCellEditMode({ id, field: "name" });
      // });
    };

    const handleDownloadStaff = () => {
      let staffAoa = createStaffArray();

      //download excel output
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(staffAoa);
      XLSX.utils.book_append_sheet(wb, ws, "PayWellStaff");
      XLSX.writeFile(wb, "PayWellStaff.xlsx", { cellStyles: true });
    };

    const createStaffArray = () => {
      //get buckets
      let buckets = Object.entries(
        settings[getUrlVariableValue("settingsId")].classSettings.classBuckets
      ).sort((a, b) => {
        if (a[0] < b[0]) {
          return -1; // a comes first
        }
        if (a[0] > b[0]) {
          return 1; // b comes first
        }
        return 0; // a and b are equal
      });

      //iterate through staff
      let staffValues = Object.values(settings.staff.staff).filter((item) =>
        checkBrandAndLocation(item.brand, item.locations, settings)
      );

      let staffAoa = [
        [
          "*user id*",
          "name",
          "email",
          "alternateNames",
          "type",
          "homeLocation",
          "homeRegion",
          "idString",
          "secondIdString",
          "titles",
          "locations",
          "hourly",
          "secondaryHourly",
        ],
      ];
      for (let i = 0; i < staffValues.length; i++) {
        let staffArray = [];
        let staff = staffValues[i];
        staffArray.push(staff.id);
        staffArray.push(staff.name);
        staffArray.push(staff.email);
        staffArray.push(staff.alternateNames.toString());
        staffArray.push(staff.type);
        staffArray.push(staff.homeLocation);
        staffArray.push(staff.homeRegion);
        staffArray.push(staff.idString);
        staffArray.push(staff.secondIdString);
        staffArray.push(staff.titles.toString());
        staffArray.push(staff.locations.toString());
        staffArray.push(staff.hourly);
        staffArray.push(staff.secondaryHourly);

        for (let x = 0; x < buckets.length; x++) {
          //let standardBucketName = buckets[x][0];
          let bucketName =
            buckets[x][0].charAt(0).toLowerCase() +
            buckets[x][0].slice(1) +
            "Rate";
          let bucketDetails = buckets[x][1];
          if (bucketDetails.rate.includeZero === "Paid A Different Amount") {
            if (i === 0) {
              staffAoa[0].push(bucketName + ".zero");
            }
            staffArray.push(staff[bucketName].zero);
          } else if (bucketDetails.rate.includeZero === "Not Paid") {
            if (i === 0) {
              staffAoa[0].push(bucketName + ".zero");
            }
            staffArray.push("0");
          } else if (bucketDetails.rate.includeZero === "Paid The Base Rate") {
            //dont add anything
          }

          if (bucketDetails.rate.structure === "None (Zero)") {
            continue;
          }

          if (bucketDetails.rate.structure === "Base Rate + Per Head Bonus") {
            if (i === 0) {
              staffAoa[0].push(bucketName + ".base");
              staffAoa[0].push(bucketName + ".perHead");
              staffAoa[0].push(bucketName + ".afterAttendee");
            }
            staffArray.push(staff[bucketName].base);
            staffArray.push(staff[bucketName].perHead);
            staffArray.push(staff[bucketName].afterAttendee);
          } else if (bucketDetails.rate.structure === "Flat Rate") {
            if (i === 0) {
              staffAoa[0].push(bucketName + ".flat");
            }
            staffArray.push(staff[bucketName].flat);
          } else if (bucketDetails.rate.structure === "Custom Amount") {
            if (i === 0) {
              staffAoa[0].push(bucketName + ".custom");
            }
            staffArray.push(staff[bucketName].custom.toString());
          }
        }

        //add commission
        if (i === 0) {
          staffAoa[0].push("commissionRate.agreement");
          staffAoa[0].push("commissionRate.retail");
        }
        staffArray.push(staff.commissionRate.agreement);
        staffArray.push(staff.commissionRate.retail);

        staffAoa.push(staffArray);
      }
      return staffAoa;
    };

    const handleUploadStaff = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const readFile = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const uploadedAoa = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            });
            resolve(uploadedAoa);
          };

          reader.onerror = (error) => reject(error);

          reader.readAsArrayBuffer(file);
        });
      };

      let uploadedAoa = await readFile(file);

      let staffAoa = createStaffArray();
      let differences = compareArrays(staffAoa, uploadedAoa);

      for (let d = 0; d < differences.length; d++) {
        //temporary ignore array type items
        if (
          differences[d].title.includes("locations") ||
          differences[d].title.includes("alternateNames") ||
          differences[d].title.includes("titles") ||
          differences[d].title.includes("custom")
        ) {
          continue;
        }
        let difference = differences[d];
        let staffMember = settings.staff.staff[difference.id];
        if (!staffMember) {
          continue;
        }
        let updatedRow = { ...staffMember };

        const titleParts = difference.title.split(".");

        if (titleParts.length === 1) {
          // Update top-level property
          updatedRow[difference.title] = difference["new-value"];
        } else if (titleParts.length === 2) {
          // Update nested property
          const [parentProp, childProp] = titleParts;
          updatedRow[parentProp] = {
            ...updatedRow[parentProp],
            [childProp]: difference["new-value"],
          };
        }

        // Additional updates for the row
        updatedRow.updated = true;
        updatedRow.status = "update";
        updatedRow.statusField = titleParts[titleParts.length - 1];

        let newRev = new RevertList(changes.getValues());
        newRev.addElement(
          updatedRow.id,
          difference["old-value"],
          difference["new-value"]
        );
        setChanges(newRev);

        dispatch({
          type: "UPDATE_STAFF_SETTINGS_STAFF_MEMBER",
          uid: getUID(),
          id: updatedRow.id,
          newStaff: updatedRow,
        });
      }
    };

    const compareArrays = (array1, array2) => {
      // Extract titles from the first row of array1
      const titles = array1[0];

      // Convert array1 to a map for easy lookup, skipping the first row
      const map1 = new Map(array1.slice(1).map((item) => [item[0], item]));

      // Initialize an array to hold the differences, skipping the first row of array2
      let differences = [];

      // Iterate over array2 to find and record differences, skipping the first row
      array2.slice(1).forEach((row2) => {
        const id = row2[0];
        const row1 = map1.get(id);

        if (row1) {
          row2.forEach((item, index) => {
            if (item !== row1[index]) {
              // Record the difference
              differences.push({
                id: id,
                title: titles[index],
                "old-value": row1[index],
                "new-value": item,
              });
            }
          });
        }
      });

      return differences;
    };

    const fileInputRef = useRef(null);

    return (
      <GridToolbarContainer>
        <div class="grid-toolbar-container">
          <div className="grid-toolbar-container__buttons">
            <Button hidden={pageTabsValue == 1} color="primary" startIcon={<AddIcon />} onClick={handleClick}>
              Add staff
            </Button>
            <Tooltip title="Export">
              <Button color="primary" onClick={handleDownloadStaff} startIcon={<FileDownloadRoundedIcon />}>
                Export
              </Button>
            </Tooltip>
            <Tooltip title="Upload Staff">
              <Button
                color="primary"
                onClick={() => fileInputRef.current.click()}
                startIcon={<FileUploadRoundedIcon />}
              >
                Upload Staff
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleUploadStaff}
                  accept=".xlsx, .xls"
                  style={{ display: "none" }} // Hide the file input
                />
              </Button>
            </Tooltip>
          </div>
          <GridToolbarQuickFilter />
        </div>
      </GridToolbarContainer>
    );
  });



  function renderLocationsEdit(props) {
    const { value, field } = props;
    let values = props.row.locations;
    let limit = 0;

    let options = ["All Studios"];

    if (settings && settings.staff.studiosFromAllPresets) {
      options = ["All Studios", ...settings.staff.studiosFromAllPresets];
    }

    const handleChange = (newValue, id) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const handleRef = (element) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);

        input?.focus();
      }
    };

    return (
      <MultipleSelectPlaceholder
        limitTags={limit}
        options={options}
        placeholder="0 Locations Selected"
        id={props.id}
        ref={handleRef}
        onChange={handleChange}
        // width={250}
        label="Studio"
        default={values}
        name="Studio"
      ></MultipleSelectPlaceholder>
    );
  }

  function renderAttributeListBoxEdit(props) {
    const { value, field } = props;
    let label = "Name(s)";
    let values = props.row.alternateNames;
    let limit = 2;
    let free = true;
    let options = [];
    let placeholder = "";
    if (props.field === "titles") {
      label = "Title(s)";
      values = props.row.titles;
      options = props.colDef.valueOptions;
    }

    let apiRef = useGridApiContext();

    const handleChange = (newValue, id) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    const handleRef = (element) => {
      if (element) {
        const input = element.querySelector(`input[value="${value}"]`);

        input?.focus();
      }
    };

    return (
      <AttributeListBox
        autoSelect
        placeholder={placeholder}
        limitTags={limit}
        ref={handleRef}
        onChange={handleChange}
        id={props.id}
        multiple
        freeSolo={free}
        default={values}
        size={"100%"}
        style={{ backgroundColor: "green" }}
        label={label}
        options={options}
        clearOnBlur={false}
      ></AttributeListBox>
    )
  }

  // Helper functions
  const numberCheck = (value) => {
    var regExp = /[a-zA-Z]/g;
    if (regExp.test(value)) {
      return true;
    }
    return false;
  };

  function buildColumns(type, bucket) {
    let columnArray = [];
    if (type === "Info") {
      return buildInfoColumns();
    } else if (type === "hourlyRate") {
      return buildHourlyColumns();
    } else if (type === "commissionRate") {
      return buildSalesCommissionColumns();
    } else {
      addNameColumn(columnArray);
      addZeroColumn(columnArray, bucket, type);
      addRateColumns(columnArray, bucket, type);
    }

    //Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets)
    return columnArray;
  }

  function buildInfoColumns() {
    // let apiRef = useGridApiRef();
    let types;
    if (
      settings &&
      settings[getUrlVariableValue("settingsId")] &&
      settings[getUrlVariableValue("settingsId")].generalSettings &&
      settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes
    ) {
      types = settings[
        getUrlVariableValue("settingsId")
      ].generalSettings.staffTypes.map((obj) => obj.name);
    } else {
      types = ["Instructor", "Non-Instructor"];
    }

    // staffFields: [
    //x   {name: "Staff Name", field: "name", type: "text", description: "Full Name (First Last)", required: true, default: "", options: null, order: 0},
    //x   {name: "Type", field: "type", type: "singleSelect", description: "Do they teach any classes?", required: true, default: "Instructor", options: null, order: 1},
    //x   {name: "Alternate Names", field: "alternateNames", type: "list", description: "Other names within ClubReady, Time Clock System, Payroll Processor...", required: false, default: [], options: null, order: 2},
    //x   {name: "Email", field: "email", type: "text", description: "For PayWell PayStub Emailer Only", required: false, default: "", options: null, order: 3},
    //x   {name: "ID", field: "idString", type: "text", description: "For Payroll Processor Integration Only", required: false, default: "", options: null, order: 4},
    //x   {name: "Secondary ID", field: "secondIdString", type: "text", description: "For Payroll Processor Integration Only", required: false, default: "", options: null, order: 5},
    //x   {name: "Titles", field: "titles", type: "list", description: "For Payroll Processor Integration Only", required: false, default: [], options: null, order: 6},
    //x   {name: "Locations", field: "locations", type: "list", description: "All studios worked at", required: true, default: [], options: null, order: 7},
    //   {name: "Home Location", field: "homeLocation", type: "singleSelect", description: "Do they have a home location?", required: false, default: "", options: null, order: 8},
    //   {name: "Home Region", field: "homeRegion", type: "text", description: "Do they have a home region?", required: false, default: "", options: null, order: -1},
    //   {name: "Pay Tier", field: "tier", type: "singleSelect", description: "What is their default pay rate/tier", required: false, default: "", options: null, order: -1}, //new
    //   {name: "Test Field", field: "test", type: "text", description: "Test Field", required: false, default: "", options: null, order: -1}, //new
    // ]

    let staffFields = settings[getUrlVariableValue("settingsId")].generalSettings.staffFields;
    let orderedFields = staffFields.filter(field => field.order >= 0).sort((a, b) => a.order - b.order);

    let defaultInfoColumns1 = [];

    orderedFields.forEach(attribute => {
      const columnConfig = {
        field: attribute.field,
        width: 200,
        editable: true,
        renderHeader: () => <strong>{attribute.name}</strong>,
        description: attribute.description,
        preProcessEditCellProps: (params) => {
          const hasError = attribute.required && (!params.props.value);
          if (hasError) {
            processRowUpdateError({ message: "Required Field", severity: "error" });
          } else {
            processRowUpdateError(null);
          }
          return { ...params.props, error: hasError };
        },
        valueSetter: (params) => {
          const value = params.value || attribute.default;
          return { ...params.row, [attribute.field]: value };
        }
      };

      // Additional configurations for specific fields
      switch (attribute.field) {
        case 'name':
          columnConfig.preProcessEditCellProps = (params) => {
            const errorMessage = validateName(params.props.value);
            const hasError = errorMessage !== null;
            if (hasError) {
              processRowUpdateError({ message: errorMessage, severity: "error" });
            } else {
              processRowUpdateError(null);
            }
            return { ...params.props, error: hasError };
          };
          break;
        case 'type':
          columnConfig.type = "singleSelect";
          columnConfig.valueOptions = settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes.map(type => type.name);
          break;
        case 'alternateNames':
        case 'titles':
          columnConfig.renderEditCell = renderAttributeListBoxEdit;
          columnConfig.valueOptions = attribute.options;
          columnConfig.valueFormatter = ({ value }) => attributeArrayRead(value);
          break;
        case 'email':
        case 'idString':
        case 'secondIdString':
          break;
        case 'locations':
          columnConfig.width = 450;
          columnConfig.renderEditCell = renderLocationsEdit;
          columnConfig.valueFormatter = ({ value }) => locationsArrayRead(value);
          break;
        case 'homeLocation':
          columnConfig.type = "singleSelect";
          columnConfig.valueOptions = settings[getUrlVariableValue("settingsId")].generalSettings.studios;
          break;
        default:
          columnConfig.type = attribute.type;
          columnConfig.valueOptions = attribute.options;
      }

      defaultInfoColumns1.push(columnConfig);
    });

    return defaultInfoColumns1;
  }

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const attendeeFormat = (value, label) => {
    if (parseInt(value) === 1) {
      return value + " " + label + " attendee";
    } else {
      return value + " " + label + " attendees";
    }
  };

  const percentOrValueFormat = (value) => {
    if (value === "") {
      return "-";
    } else if (value >= 1 || value <= 0) {
      return currencyFormatter.format(value);
    } else {
      return value * 100 + "%";
    }
  };

  function buildHourlyColumns() {
    const hourlyRateColumns = [
      {
        field: "name",
        renderHeader: () => <strong>{"Staff Name"}</strong>,
        width: 150,
        editable: false,
      },
      {
        field: "hourly",
        preProcessEditCellProps: (params) => {
          let hasError = numberCheck(params.props.value);
          return { ...params.props, error: hasError };
        },
        width: 205,
        editable: true,
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        renderHeader: () => <strong>{"Primary Hourly Rate"}</strong>,
      },
      {
        field: "secondaryHourly",
        preProcessEditCellProps: (params) => {
          let hasError = numberCheck(params.props.value);
          return { ...params.props, error: hasError };
        },
        width: 205,
        editable: true,
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        renderHeader: () => <strong>{"Secondary Hourly Rate"}</strong>,
      },
    ];
    return hourlyRateColumns;
  }

  function addNameColumn(columnArray) {
    columnArray.push({
      field: "name",
      renderHeader: () => <strong>{"Instructor Name"}</strong>,
      width: 195,
      editable: false,
    });
  }

  function addZeroColumn(columnArray, bucket, type) {
    let rateObj = bucket.rate;

    if (
      (rateObj.includeZero === true ||
        rateObj.includeZero === "Paid A Different Amount") &&
      rateObj.structure !== "None (Zero)"
    ) {
      let zeroCol = {
        field: "zero",
        editable: true,
        width: 175,
        valueFormatter: (params) => {
          let value = parseFloat(params.value);
          if (value < 1 && value > 0) {
            return value * 100 + "%";
          } else {
            return currencyFormatter.format(value);
          }
        },
        renderHeader: () => <strong>{"Zero Attendees"}</strong>,
        valueGetter: (params) => {
          return params.row[type].zero;
        },
        valueSetter: (params) => {
          params.row[type].zero = params.value;
          return params.row;
        },
        preProcessEditCellProps: (params) => {
          return { ...params.props, error: numberCheck(params.props.value) };
        },
      };
      columnArray.push(zeroCol);
    }
  }

  function addRateColumns(columnArray, bucket, type) {
    let rateObj = bucket.rate;
    if (rateObj.structure === "Flat Rate") {
      let headerLabel =
        "Flat Rate (1-" + convertInputBoxValue(bucket.capacity) + " attendees)";
      let flatCol = {
        field: "flat",
        editable: true,
        width: 245,
        valueFormatter: (params) => {
          let value = parseFloat(params.value);
          if (value < 1 && value > 0) {
            return value * 100 + "%";
          } else {
            return currencyFormatter.format(value);
          }
        },
        renderHeader: () => <strong>{headerLabel}</strong>,
        valueGetter: (params) => {
          return params.row[type].flat;
        },
        valueSetter: (params) => {
          params.row[type].flat = params.value;
          return params.row;
        },
        preProcessEditCellProps: (params) => {
          return { ...params.props, error: numberCheck(params.props.value) };
        },
      };
      columnArray.push(flatCol);
    } else if (rateObj.structure === "Base Rate + Per Head Bonus") {
      let headerBaseLabel = "Base Rate";
      let w = 175;
      if (type === "privateRate") {
        headerBaseLabel = "Semi-Private Base Rate (2 Attendees)";
        w = 275;
      }
      let baseCol = {
        field: "base",
        width: w,
        editable: true,

        renderHeader: () => <strong>{headerBaseLabel}</strong>,
        valueFormatter: (params) => {
          let value = parseFloat(params.value);
          if (value < 1 && value > 0) {
            return value * 100 + "%";
          } else {
            return currencyFormatter.format(value);
          }
        },
        valueGetter: (params) => {
          return params.row[type].base;
        },
        valueSetter: (params) => {
          params.row[type].base = params.value;
          return params.row;
        },
        preProcessEditCellProps: (params) => {
          return { ...params.props, error: numberCheck(params.props.value) };
        },
      };

      let headerBonusLabel = "Per Head Bonus";
      let wHead = 175;
      if (type === "privateRate") {
        headerBonusLabel = "Semi-Private Per Head Bonus (2+ Attendees)";
        wHead = 305;
      }
      let bonusCol = {
        field: "perHead",
        width: wHead,
        editable: true,

        renderHeader: () => <strong>{headerBonusLabel}</strong>,
        valueFormatter: (params) => {
          let value = parseFloat(params.value);
          if (value < 1 && value > 0) {
            return value * 100 + "%";
          } else {
            return currencyFormatter.format(value);
          }
        },
        valueGetter: (params) => {
          return params.row[type].perHead;
        },
        valueSetter: (params) => {
          params.row[type].perHead = params.value;
          return params.row;
        },
        preProcessEditCellProps: (params) => {
          return { ...params.props, error: numberCheck(params.props.value) };
        },
      };

      let attendeeLabel = "";
      let wAfter = 160;
      if (type === "privateRate") {
        attendeeLabel = "Semi-Private";
        wAfter = 190;
      }
      let attendeeStartCol = {
        field: "afterAttendee",
        width: wAfter,
        editable: true,

        renderHeader: () => <strong>{"Starting AFTER"}</strong>,
        valueFormatter: ({ value }) => attendeeFormat(value, attendeeLabel),
        valueGetter: (params) => {
          if (params.row[type].afterAttendee === null) {
            return rateObj.defaultAfterAttendee;
          }
          return params.row[type].afterAttendee;
        },
        valueSetter: (params) => {
          params.row[type].afterAttendee = params.value;
          return params.row;
        },
        preProcessEditCellProps: (params) => {
          return { ...params.props, error: numberCheck(params.props.value) };
        },
      };
      columnArray.push(baseCol);
      columnArray.push(bonusCol);
      columnArray.push(attendeeStartCol);
    } else if (rateObj.structure === "Custom Amount") {
      let capacity = parseInt(convertInputBoxValue(bucket.capacity));
      if (type !== "privateRate") {
        for (let i = 1; i <= capacity; i++) {
          columnArray.push({
            field: "attendee" + i,
            width: 175,
            editable: true,

            renderHeader: () => <strong>{i + " Attendees"}</strong>,
            valueFormatter: (params) => {
              let value = parseFloat(params.value);
              if (value < 1 && value > 0) {
                return value * 100 + "%";
              } else {
                return currencyFormatter.format(value);
              }
            },
            valueGetter: (params) => {
              let rowArray = params.row[type].custom;
              if (rowArray.length >= i) {
                return params.row[type].custom[i];
              }
              return "0";
            },
            valueSetter: (params) => {
              params.row[type].custom[i] = params.value;
              params.row[type].custom[0] = params.row[type].zero;
              return params.row;
            },
            preProcessEditCellProps: (params) => {
              return {
                ...params.props,
                error: numberCheck(params.props.value),
              };
            },
          });
        }
      } else {
        for (let i = 2; i <= capacity; i++) {
          columnArray.push({
            field: "attendee" + i,
            width: 215,
            editable: true,

            renderHeader: () => (
              <strong>{i + " Semi-Private Attendees"}</strong>
            ),
            valueFormatter: (params) => {
              let value = parseFloat(params.value);
              if (value < 1 && value > 0) {
                return value * 100 + "%";
              } else {
                return currencyFormatter.format(value);
              }
            },
            valueGetter: (params) => {
              let rowArray = params.row[type].custom;
              if (rowArray.length >= i) {
                return params.row[type].custom[i];
              }
              return "0";
            },
            valueSetter: (params) => {
              params.row[type].custom[i] = params.value;
              params.row[type].custom[0] = params.row[type].zero;
              params.row[type].custom[1] = params.row[type].private;
              return params.row;
            },
            preProcessEditCellProps: (params) => {
              return {
                ...params.props,
                error: numberCheck(params.props.value),
              };
            },
          });
        }
      }
    }
  }

  function buildSalesCommissionColumns() {
    const salesColumns = [
      {
        field: "name",
        renderHeader: () => <strong>{"Staff Name"}</strong>,
        width: 175,
        editable: false,
      },
      {
        field: "agreement",
        editable: true,
        width: 175,
        valueFormatter: ({ value }) => percentOrValueFormat(value),
        renderHeader: () => <strong>{"Agreement Rate"}</strong>,
        valueGetter: (params) => {
          return params.row.commissionRate.agreement;
        },
        valueSetter: (params) => {
          params.row.commissionRate.agreement = params.value;
          return params.row;
        },
      },
      {
        field: "retail",
        editable: true,
        width: 175,
        valueFormatter: ({ value }) => percentOrValueFormat(value),
        renderHeader: () => <strong>{"Retail Rate"}</strong>,
        valueGetter: (params) => {
          return params.row.commissionRate.retail;
        },
        valueSetter: (params) => {
          params.row.commissionRate.retail = params.value;
          return params.row;
        },
      },
    ];

    let allDistinctRates = getDistinctSalesRates();
    //add custom rates here
    for (let i = 0; i < allDistinctRates.length; i++) {
      salesColumns.push({
        field: allDistinctRates[i],
        editable: true,
        width: 175,
        valueFormatter: ({ value }) => percentOrValueFormat(value),
        renderHeader: () => <strong>{allDistinctRates[i]}</strong>,
        valueGetter: (params) => {
          return params.row.commissionRate[allDistinctRates[i]] || "";
        },
        valueSetter: (params) => {
          params.row.commissionRate[allDistinctRates[i]] = params.value || "";
          return params.row;
        },
      });
    }

    return salesColumns;
  }

  const getDistinctSalesRates = () => {
    let allDistinctRates = [];
    let salesBuckets = settings[getUrlVariableValue("settingsId")].salesSettings.salesBuckets;
    for (let bucketName in salesBuckets) {
      let bucketRates = salesBuckets[bucketName].distinctRates;
      for (let j = 0; j < bucketRates.length; j++) {
        allDistinctRates.push(bucketName + "-" + bucketRates[j]);
      }
    }

    return allDistinctRates;
  }


  const findBrand = (settings) => {
    // Access the brand from the settings
    let brand =
      settings[getUrlVariableValue("settingsId")].generalSettings.brand;
    let studios =
      settings[getUrlVariableValue("settingsId")].generalSettings.studios;

    // If brand is not defined, find it
    if (!brand) {
      const brandList = brands;

      for (let element1 of brandList) {
        for (let element2 of studios) {
          if (element2.includes(element1)) {
            brand = element1;
            break;
          }
        }

        if (brand) {
          break;
        }
      }
    }

    return brand;
  };
  const checkBrandAndLocation = (staffBrand, staffLocation, settings) => {
    if (staffBrand === "") {
      staffBrand = findBrand(settings);
    }
    // Access the brand from the settings
    let brand =
      settings[getUrlVariableValue("settingsId")].generalSettings.brand;
    let studios =
      settings[getUrlVariableValue("settingsId")].generalSettings.studios;

    // If brand is not defined, find it
    if (!brand) {
      const brandList = brands;

      for (let element1 of brandList) {
        for (let element2 of studios) {
          if (element2.includes(element1)) {
            brand = element1;
            break;
          }
        }

        if (brand) {
          break;
        }
      }
    }

    let brandMatch = brand === staffBrand || !brand;

    let locationMatch = staffLocation.length === 0;
    for (let i = 0; i < staffLocation.length; i++) {
      for (let j = 0; j < studios.length; j++) {
        if (
          staffLocation[i] === studios[j] ||
          staffLocation[i] === "All Studios"
        ) {
          locationMatch = true;
          break;
        }
      }
    }

    return brandMatch && locationMatch;
  };
  // Ensure settings and settings.staff are defined before memoizing rows
  const memoizedRatesRows = React.useMemo(() => {
    if (!settings || !settings.staff) return [];
    return Object.values(settings.staff.staff).filter((s) => {
      if (!checkBrandAndLocation(s.brand, s.locations, settings)) {
        return false;
      }

      const staffTypes = settings[getUrlVariableValue("settingsId")].generalSettings.staffTypes;

      let payType;

      if (tableTabsValue === 0) {
        payType = "hourlyPay";
      } else if (
        tableTabsValue ===
        Object.keys(settings[getUrlVariableValue("settingsId")].classSettings.classBuckets).length + 1
      ) {
        payType = "commissionPay";
      } else {
        payType = "sessionPay";
      }

      for (let i = 0; i < staffTypes.length; i++) {
        if (staffTypes[i].name === s.type) {
          return staffTypes[i][payType];
        }
      }
      return true;
    });
  }, [settings, tableTabsValue]);

  const memoizedInfoRows = React.useMemo(() => {
    if (!settings || !settings.staff) return [];
    return Object.values(settings.staff.staff).filter((item) =>
      checkBrandAndLocation(item.brand, item.locations, settings)
    );
  }, [settings]);

  return settings.hasOwnProperty("1") && settings.hasOwnProperty("staff") ? (
    <Box className="settings-page">
      <Typography variant="h2" className="settings-page__title">
        Staff
      </Typography>
      <Tabs
        className="settings-page__tabs"
        value={pageTabsValue}
        onChange={handleChangePageTabs}
        aria-label="basic tabs example"
      >
        <Tab className={`settings-page__tab`} label="Info" {...tabProps(0)} />
        <Tab className={`settings-page__tab`} label="Rates" {...tabProps(1)} />
      </Tabs>
      <Box className="settings-page__tab-content">

        <TabPanel
          className="settings-page__tab-panel"
          value={pageTabsValue}
          index={0}
        >
          {pageTabsValue === 0 && (
            <Box className="table">
              <Box
                className="table__data-grid-container table__data-grid-container--staff-rates"
                sx={{
                  "& .actions": {
                    color: "text.secondary",
                  },
                  "& .textPrimary": {
                    color: "text.primary",
                  },
                }}
              >
                <StyledBox>
                  <StripedDataGrid
                    className="table__data-grid-pro table__data-grid-pro--staff-info"
                    rows={memoizedInfoRows}
                    columns={buildColumns("Info", null).concat(actionInfoColumn)}
                    rowModesModel={rowModesModel}
                    apiRef={apiRef}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={processRowUpdateError}
                    onCellClick={handleCellEditStart}
                    onCellEditStop={handleCellEditStop}
                    getRowClassName={(params) =>
                      params.row.status
                        ? `MuiDataGrid-row--${params.row.status}`
                        : ""
                    }
                    getCellClassName={(params) => {
                      if (
                        params.row["statusField"] &&
                        params.row["statusField"] == params.field
                      ) {
                        return `MuiDataGrid-cell--${params.row.status}`;
                      }
                    }}
                    components={{
                      Toolbar: EditToolbar,
                    }}
                    componentsProps={{
                      toolbar: {
                        editingRowId,
                        setEditingRowId,
                        setEditingCellField,
                        rowModesModel,
                        setSnackbar,
                        apiRef,
                      },
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    initialState={{
                      pinnedColumns: { right: ["actions"], left: ["name"] },
                      sorting: {
                        sortModel: [{ field: "name", sort: "asc" }],
                      },
                    }}
                    sortModel={[
                      {
                        field: 'name',
                        sort: 'asc',  // Sort ascending
                      },
                    ]}

                    getRowHeight={() => "auto"}
                    hideFooter
                    sx={{ border: 1 }}
                  />
                </StyledBox>
              </Box>
            </Box>
          )}
        </TabPanel>
        <TabPanel
          className="settings-page__tab-panel"
          value={pageTabsValue}
          index={1}
        >
          {pageTabsValue === 1 && (
            <Box className="table">
              <Tabs
                className="table__tabs"
                value={tableTabsValue}
                onChange={handleTableTabsChange}
                aria-label="basic tabs example"
                centered
                variant="scrollable"
                scrollButtons="auto"
                // scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  className={`settings-page__tab settings-page__tab${tableTabsValue == 0 ? "--active" : ""
                    }`}
                  label="Hourly"
                  {...tabProps(0)}
                />

                {Object.entries(
                  settings[getUrlVariableValue("settingsId")].classSettings
                    .classBuckets
                )
                  .sort()
                  .map((tab, index) => (
                    <Tab
                      key={tab[0]}
                      onClick={(event) => handleTableTabsChange(event, index + 1)}
                      className={`settings-page__tab settings-page__tab${tableTabsValue === index + 1 ? "--active" : ""
                        }`}
                      label={tab[0].replace(/(?<!^)([A-Z])/g, "-$1")}
                      {...tabProps(index + 1)}
                    />
                  ))}

                <Tab
                  onClick={() =>
                    handleTableTabsChange(
                      event,
                      Object.keys(
                        settings[getUrlVariableValue("settingsId")].classSettings
                          .classBuckets
                      ).length + 1
                    )
                  }
                  className={`settings-page__tab settings-page__tab${tableTabsValue ==
                    Object.keys(
                      settings[getUrlVariableValue("settingsId")].classSettings
                        .classBuckets
                    ).length +
                    1
                    ? "--active"
                    : ""
                    }`}
                  // className="table__tab"
                  label="Sales-Commission"
                  {...tabProps(
                    Object.keys(
                      settings[getUrlVariableValue("settingsId")].classSettings
                        .classBuckets
                    ).length + 1
                  )}
                />
              </Tabs>

              <Box
                className="table__data-grid-container table__data-grid-container--staff-rates"
                sx={{
                  "& .actions": {
                    color: "text.secondary",
                  },
                  "& .textPrimary": {
                    color: "text.primary",
                  },
                }}
              >
                <StyledBox>
                  <StripedDataGrid
                    className="table__data-grid-pro table__data-grid-pro--staff-rates"
              

                    rows={memoizedRatesRows}

                    columns={ratesColumns.concat(actionRateColumn)}
                    apiRef={apiRef}
                    rowModesModel={rowModesModel}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={processRowUpdateError}
                    onCellClick={handleCellEditStart}
                    onCellEditStop={handleCellEditStop}
                    getRowClassName={(params) =>
                      params.row.status
                        ? `MuiDataGrid-row--${params.row.status}`
                        : ""
                    }
                    getCellClassName={(params) => {
                      if (
                        params.row["statusField"] &&
                        params.row["statusField"] == params.field
                      ) {
                        return `MuiDataGrid-cell--${params.row.status}`;
                      }
                    }}
                    components={{
                      Toolbar: EditToolbar,
                    }}
                    componentsProps={{
                      toolbar: {
                        editingRowId,
                        setEditingRowId,
                        setEditingCellField,
                        rowModesModel,
                        setSnackbar,
                        apiRef,
                      },
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    initialState={{
                      pinnedColumns: { right: ["actions"], left: ["name"] },
                    }}
                    sortModel={[
                      {
                        field: 'name',
                        sort: 'asc',  // Sort ascending
                      },
                    ]}
                    hideFooter
                    sx={{ border: 1 }}
                  />
                </StyledBox>
              </Box>
            </Box>
          )}
        </TabPanel>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      {addStaffModal && (
        <AddStaffDialogSettings
          open={addStaffModal}
          onClose={handleAddStaffDialogClose}
          onSave={handleAddStaffDialogSave}
        />
      )}
    </Box>
  ) : (
    <Box className="settings-page">
      <Skeleton>
        <Typography variant="h2" className="settings-page__title">
          Studios
        </Typography>
      </Skeleton>
      <Divider className="settings-page__divider" />
      <Skeleton className="settings-page__attribute-box settings-page__attribute-box--skeleton"></Skeleton>
    </Box>
  );
}
